import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse, ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  AddCompanyTypePayload,
  DeleteCompanyTypePayload,
  FetchCompanyTypePayload,
  ICompanyType,
  UpdateCompanyTypePayload,
} from '../types/company-type.types';

export const companyTypeApi = createApi({
  reducerPath: 'companyTypeApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/company/types`),
  endpoints: (builder) => ({
    fetchCompanyTypes: builder.query<ApiPaginationResponse<ICompanyType[]>, FetchCompanyTypePayload>({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),

    addCompanyType: builder.mutation<ApiResponse<ICompanyType>, AddCompanyTypePayload>({
      query: (payload) => ({
        url: '',
        method: 'POST',
        body: payload,
      }),
    }),

    updateCompanyType: builder.mutation<ApiResponse<ICompanyType>, UpdateCompanyTypePayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    deleteCompanyType: builder.query<ApiResponse<any>, DeleteCompanyTypePayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyFetchCompanyTypesQuery,
  useAddCompanyTypeMutation,
  useLazyDeleteCompanyTypeQuery,
  useUpdateCompanyTypeMutation,
} = companyTypeApi;
