import { TABLE_PAGE_SIZE } from 'general/constants';

import API from '../api/api';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';

const { AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate } = baseDataReducer('productTypes');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

/* 
    On add, remove or clear filters we are setting offset to 0 and clearing items under the hood(see baseDataReducer.js) 
*/
export const onProductTypesFilter = thunks.onFilter;
export const onProductTypesSort = thunks.onSort;

const productTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const setProductTypeOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const clearProductTypes = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getProductTypes =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.marketplace.getProductTypes({ offset, limit, ...params });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
    } catch (e) {
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error when loading product types', deb_info: e });
    }
  };

// export const removeProductType = (id) => async (dispatch) => {
//     try {
//         dispatch(AC.toggleLoading(true));
//         let data = await API.marketplace.removeProductType({id});
//         dispatch(AC.toggleInit(false));
//         dispatch(AC.removeItem(id));
//         dispatch(AC.toggleLoading(false));
//         return true;
//     } catch (error) {
//         console.log(error);
//         onError({status: 0, message: "Error on remove product type!", deb_info: error})
//         dispatch(AC.toggleLoading(false));
//         return false;
//     }
// }

export const editProductType = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    dispatch(AC.editItem(data));
    let dataToSend = helpers.formatData(data, {
      removeIfEmpty: ['password'],
    });
    let status = await API.marketplace.editProductType(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit product type!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const clearProductTypeItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};

export default productTypesReducer;
