import { ApiGetRequest } from 'types';

import { IExchangeListingItem } from 'modules/necs/common';

export enum ENecsCertificateModals {
  EXCHANGE = 'exchange',
}

export interface INecsCertificateModal {
  type: ENecsCertificateModals;
}

export interface IGoCertificate {
  id: number;
  exchange_list: IExchangeListingItem[];
  bundle_id: number;
  account_name: string;
  account_number: string;
  production_device_name: string;
  production_device_id: string;
  type_of_installation_code: string;
  type_of_installation_path: string[];
  energy_source_code: string;
  energy_source_code_path: string[];
  volume: number;
  reserved_volume: number;
  date_of_expiration: string;
  face_value: string;
  start_certificate_number: string;
  end_certificate_number: string;
  on_exchange_quantity: number;
  rdid: string;
  production_period_start: string;
  production_period_end: string;
  issued_date: string;
  comment: string;
  production_device: number;
}

export interface FetchGoCertificatePayload extends ApiGetRequest {
  filter_by_has_exchanges?: boolean | null;
  filter_by_has_comment?: boolean | null;
  filter_by_production_device_id?: string | null;
  filter_by_prod_start_date?: string | null;
  filter_by_prod_end_date?: string | null;
  filter_by_exp_start_date?: string | null;
  filter_by_exp_end_date?: string | null;
  filter_by_type_of_installation?: string | null;
  filter_by_energy_source?: string | null;
  order_by_bundle_id?: 'asc' | 'desc';
  order_by_production_device_name?: 'asc' | 'desc';
  order_by_volume?: 'asc' | 'desc';
  order_by_comment?: 'asc' | 'desc';
}

export interface UpdateGoCertificatePayload {
  id: number | string;
  comment?: string;
  go_exchanges?: IExchangeListingItem[];
}
