import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse } from 'types';
import { generateFormData } from 'utils';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  DeleteMediaRequest,
  FetchMediaPayload,
  IMedia,
  UploadMediaRequest,
  UploadMediaResponse,
} from '../types/media.types';

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/media`),
  endpoints: (builder) => ({
    fetchMedia: builder.query<ApiPaginationResponse<IMedia[]>, FetchMediaPayload>({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),

    uploadMedia: builder.mutation<UploadMediaResponse, UploadMediaRequest>({
      query: (payload) => ({
        url: `/upload/${payload.params.modelType}/${payload.params.id}?collection=${payload.params.collection}`,
        method: 'POST',
        body: generateFormData(payload.body),
      }),
    }),

    deleteMedia: builder.mutation<UploadMediaResponse, DeleteMediaRequest>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUploadMediaMutation, useDeleteMediaMutation, useLazyFetchMediaQuery } = mediaApi;
