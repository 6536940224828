import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as QRCode from 'easyqrcodejs';

import { useAuthSession } from 'modules/auth-session';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import NibButton from 'components/common/NibButton/NibButton';
import NibLink from 'components/common/NibLink/NibLink';
import NibPasswordInput from 'components/common/NibPasswordInput';

import css from './loginPage.module.sass';

const LoginPage = ({ onTwoFa }) => {
  const code = useRef();
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');

  const returnUrl = new URLSearchParams(useLocation().search).get('return');
  const { loginHandler, checkSessionHandler } = useAuthSession();

  const { platformSettings } = useSelector((state) => state.app);
  const isQrCodeLogo = Boolean(platformSettings?.qrCodeLogo);

  useEffect(() => {
    if (process.env.REACT_APP_LOGIN_PAGE_QR_CODE_LINK) {
      const text = process.env.REACT_APP_LOGIN_PAGE_QR_CODE_LINK;
      let options = {
        text,
        logo: platformSettings?.qrCodeLogo || '',
        width: 140,
        height: 140,
        colorDark: '#3d3d3d',
        colorLight: '#cecece',
        quietZone: 1,
        quietZoneColor: 'white',
        logoWidth: 40,
        logoHeight: 40,
        logoBackgroundColor: isQrCodeLogo ? 'transparent' : '#3d3d3d',
        logoBackgroundTransparent: isQrCodeLogo,
        correctLevel: QRCode.CorrectLevel.M,
      };
      if (code) {
        new QRCode(code.current, { ...options });
      }
    }
  }, [code, platformSettings?.qrCodeLogo, isQrCodeLogo]);

  const onLogin = async (e) => {
    e.preventDefault();
    const result = await loginHandler({ login, password: pass });
    if (result && result.two_fa_method === null) {
      await checkSessionHandler();
      returnUrl ? (window.location.href = returnUrl) : history.push('/dashboard');
      return;
    }
    if (result && result.two_fa_method) {
      onTwoFa(result.two_fa_method);
    }
  };

  return (
    <div className={css.main}>
      <div className={css.block}>
        <div className={css.logo}></div>
        <div className={css.loginInfo}>
          <Typography className={css.signIn}>SIGN IN</Typography>
          <div className={css.info}>
            <Typography>Login with {platformSettings.companyName} wallet</Typography>
            <Typography className={css.infoWallet}>Open QR code scanner in wallet and scan code to log in.</Typography>
            <div style={{ marginTop: 10 }} ref={code} />
          </div>
          <Typography className={css.logIn}>Login with {platformSettings.companyName} ID</Typography>
          <div className={css.loginForm}>
            <TextField
              onChange={(e) => setLogin(e.target.value)}
              value={login}
              placeholder="Email or Username"
              variant={'outlined'}
            />
            <NibPasswordInput placeholder="Password" label="" onChange={setPass} value={pass} />
            <FormControlLabel value="top" control={<Checkbox />} label="Remember me" labelPlacement="left" />
            <NibButton className={css.loginBtn} mui variant="outlined" size="large" label={'Login'} onClick={onLogin} />
          </div>
          <div className={css.links}>
            <p>
              <NibLink to="/password-reset">Forgot password?</NibLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
