import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useUserMemberships } from '@pubnub/react-chat-components';
import { addChannelToUnread, removeChannelFromUnread, setChatUser } from '@redux/session-reducer';
import { usePubNub } from 'pubnub-react';
import { useAppSelector } from 'redux/store/store.types';

export const useChat = () => {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const { unread_channels, chatUser } = useSelector(({ session }) => session);
  const { currentUser: user } = useAppSelector((store) => store.authSession);

  const [channels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true },
  });

  useEffect(() => {
    const subscribe = {
      channels: [channels.map((c) => c.id)],
      withPresence: false,
    };

    const event_handle = async (message) => {
      if (message.message) {
        const { type, text } = message.message;
        if (type === 'text' && text.includes(`@${user.email}`)) {
          toast.dark('New message', { style: { backgroundColor: '#1a8cff' } });
        }
      }

      if (message.publisher !== user.uuid) {
        if (chatUser?.custom?.hiddenChannels) {
          let hiddenChannels = chatUser.custom.hiddenChannels.split(',');
          if (hiddenChannels.find((channelId) => channelId == message.channel)) {
            hiddenChannels = hiddenChannels.filter((channelId) => channelId != message.channel);
            let userCustom = chatUser.custom;
            userCustom.hiddenChannels = hiddenChannels.length ? hiddenChannels.join(',') : '';
            const response = await pubnub.objects.setUUIDMetadata({
              data: {
                id: chatUser.id,
                custom: {
                  ...userCustom,
                },
              },
            });
            dispatch(setChatUser(response.data));
          }
        }
        dispatch(addChannelToUnread(message.channel));
      }
    };

    const listener = {
      message: event_handle,
      file: event_handle,
    };

    pubnub.addListener(listener);

    if (channels.length > 0) {
      pubnub.subscribe(subscribe);
    }

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribe(subscribe);
    };
  });

  useEffect(() => {
    if (user) {
      pubnub.objects
        .getUUIDMetadata()
        .then((responce) => {
          if (responce.data.custom?.hiddenChannels) {
            if (unread_channels.length) {
              unread_channels.forEach((unreadChannel) => {
                if (responce.data.custom.hiddenChannels.includes(unreadChannel)) {
                  dispatch(removeChannelFromUnread(unreadChannel));
                }
              });
            }
          }
        })
        .catch((err) => {});
    }
  }, [unread_channels]);

  useEffect(() => {
    if (user) {
      pubnub.objects
        .getUUIDMetadata()
        .then((responce) => {
          let newUserData = {
            id: responce.data.id,
            name: user.fullName,
          };
          if (responce.data?.profileUrl == null && user.avatar) {
            newUserData.profileUrl = `${process.env.REACT_APP_API_URL}${user.avatar.url}`;
          }
          pubnub.objects.setUUIDMetadata({
            data: newUserData,
          });
        })
        .catch((err) => {
          // error means user hasn't been registered yet
          pubnub.objects.setUUIDMetadata({
            data: {
              name: user.fullName,
              email: user.email,
              externalId: user.id.toString(),
              profileUrl: user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar.url}` : null,
              custom: {
                nickname: user.first_name,
              },
            },
          });
        });
    }
  }, [user]);

  useEffect(() => {
    // This part to retreive unread messages
    // For retreiving unread messages we need to store lastReadTimetoken inside
    // memberships each time we receive latest message
    pubnub.objects
      .getMemberships({
        include: {
          customFields: true,
        },
      })
      .then(({ data: channels }) => {
        if (channels.length) {
          const args = {
            channels: channels.map((c) => c.channel.id.toString()),
            channelTimetokens: channels.map((c) => (c.custom?.lastReadTimetoken || 1).toString()),
          };
          pubnub
            .messageCounts(args)
            .then(({ channels }) => {
              const unreadChannels = Object.entries(channels)
                .filter(([, value]) => value > 0)
                .map(([key]) => key);
              unreadChannels.forEach((unreadChannel) => dispatch(addChannelToUnread(unreadChannel)));
            })
            .catch(console.error);
        }
      });
  }, [pubnub]);
};
