import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import { useAuthSession } from 'modules/auth-session';

import {
  useDisconnectAccountMutation,
  useGetGoAccountsMutation,
  useLazySearchByOrganizationNameQuery,
  useSaveAccountMutation,
  useSetGoAccountsMutation,
  useUpdateAccountSettingsMutation,
} from '../api/necs-account.api';
import {
  NecsAccountAC,
  setGoAccounts,
  setNecsAccount,
  setNecsAccountConnectionResult,
} from '../store/necs-account.slice';
import {
  ISearchNecsAccount,
  SaveNecsAccountPayload,
  SearchNecsAccountPayload,
  SetNecsGoAccountsPayload,
  UpdateAccountSettingPayload,
} from '../types/necs-account.types';

interface UseNecsAccount {
  saveNecsAccountHandler: (data: SaveNecsAccountPayload) => Promise<void>;
  disconnectNecsAccountHandler: () => Promise<void>;
  getGoAccountsHandler: () => Promise<void>;
  setGoAccountsHandler: (data: SetNecsGoAccountsPayload) => Promise<void>;
  updateAccountSettingsHandler: (payload: UpdateAccountSettingPayload) => Promise<void>;
  searchByOrganizationNameHandler: (payload: SearchNecsAccountPayload) => Promise<ISearchNecsAccount[]>;
}

export const useNecsAccount = (): UseNecsAccount => {
  const dispatch = useAppDispatch();

  const { checkSessionHandler } = useAuthSession();

  const [saveNecsAccountQuery] = useSaveAccountMutation();
  const [disconnectNecsAccountQuery] = useDisconnectAccountMutation();
  const [getGoAccountsQuery] = useGetGoAccountsMutation();
  const [setGoAccountsQuery] = useSetGoAccountsMutation();
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const [searchByOrganizationNameQuery] = useLazySearchByOrganizationNameQuery();

  const searchByOrganizationNameHandler = async (payload: SearchNecsAccountPayload) => {
    const { isError, data: response } = await searchByOrganizationNameQuery(payload);
    if (isError || !response?.data) return [];
    return response.data;
  };

  const saveNecsAccountHandler = async (payload: SaveNecsAccountPayload) => {
    dispatch(NecsAccountAC.toggleLoading(true));
    const { data: response, error } = await saveNecsAccountQuery(payload);
    const isError = error || !response;

    if (isError) {
      dispatch(NecsAccountAC.toggleLoading(false));
      if (error && 'data' in error) {
        const data = error.data as any;
        if (data?.necsError) {
          dispatch(
            setNecsAccountConnectionResult({
              state: 'necs-error',
              message: data.necsError,
            }),
          );
          return;
        }
      }
      toast.error(getApiErrorMessage(error) || 'Failed to connect NECS account!');
    }

    if (response) {
      setTimeout(() => {
        dispatch(setNecsAccount(response.data));
        dispatch(
          setNecsAccountConnectionResult({
            state: 'success',
            message:
              'Your NECS account was successfully connected, the certificate bundles will soon appear in our system. You can now also select which GO Accounts will be used for selling and buying certificates.',
          }),
        );
        dispatch(NecsAccountAC.toggleLoading(false));
      }, 5000);
    }
  };

  const disconnectNecsAccountHandler = async () => {
    dispatch(NecsAccountAC.toggleLoading(true));
    const { error } = await disconnectNecsAccountQuery({});
    dispatch(NecsAccountAC.toggleLoading(false));

    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to disconnect NECS account!');
      return;
    }

    toast.success('NECS account successfully disconnected!');
    dispatch(setGoAccounts([]));
    dispatch(setNecsAccount(null));
  };

  const getGoAccountsHandler = async () => {
    dispatch(NecsAccountAC.toggleLoading(true));
    const { data: response, error } = await getGoAccountsQuery({});
    dispatch(NecsAccountAC.toggleLoading(false));
    const isError = error || !response?.data;

    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS GO accounts!');
      return;
    }
    dispatch(setGoAccounts(response.data));
  };

  const setGoAccountsHandler = async (payload: SetNecsGoAccountsPayload) => {
    dispatch(NecsAccountAC.toggleLoading(true));
    const { error } = await setGoAccountsQuery(payload);

    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to set NECS GO accounts!');
      dispatch(NecsAccountAC.toggleLoading(false));
      return;
    }

    await getGoAccountsHandler();
    dispatch(NecsAccountAC.toggleLoading(false));
  };

  const updateAccountSettingsHandler = async (payload: UpdateAccountSettingPayload) => {
    dispatch(NecsAccountAC.toggleLoading(true));
    const { error } = await updateAccountSettings(payload);

    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to save Exchange Settings!');
      dispatch(NecsAccountAC.toggleLoading(false));
      return;
    }
    await checkSessionHandler();
    toast.success('Exchange Settings successfully saved!');
    dispatch(NecsAccountAC.toggleLoading(false));
  };

  return {
    disconnectNecsAccountHandler,
    getGoAccountsHandler,
    saveNecsAccountHandler,
    setGoAccountsHandler,
    updateAccountSettingsHandler,
    searchByOrganizationNameHandler,
  };
};
