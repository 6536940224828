import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  INecsWhiteList,
  NecsAddWhiteListPayload,
  NecsDeleteWhiteListPayload,
  NecsUpdateWhiteListPayload,
} from '../types';

export const necsWhiteListApi = createApi({
  reducerPath: 'necsWhiteListApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/whitelist`),
  endpoints: (builder) => ({
    fetchWhiteLists: builder.query<ApiResponse<INecsWhiteList[]>, any>({
      query: () => ({
        url: ``,
        method: 'GET',
      }),
    }),

    addWhiteList: builder.mutation<ApiResponse<INecsWhiteList>, NecsAddWhiteListPayload>({
      query: (payload) => ({
        url: ``,
        method: 'POST',
        body: payload,
      }),
    }),

    updateWhiteList: builder.mutation<ApiResponse<INecsWhiteList>, NecsUpdateWhiteListPayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    deleteWhiteList: builder.mutation<ApiResponse<any>, NecsDeleteWhiteListPayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyFetchWhiteListsQuery,
  useAddWhiteListMutation,
  useUpdateWhiteListMutation,
  useDeleteWhiteListMutation,
} = necsWhiteListApi;
