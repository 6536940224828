import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import { useDeleteMediaMutation, useLazyFetchMediaQuery, useUploadMediaMutation } from '../api/media.api';
import { MediaAC } from '../store/media.slice';
import {
  ConvertMediaPayload,
  DeleteMediaRequest,
  FetchMediaPayload,
  IMedia,
  UploadMediaPayload,
} from '../types/media.types';

interface UseMedia {
  uploadMediaHandler: (payload: UploadMediaPayload) => Promise<boolean>;
  deleteMediaHandler: (payload: DeleteMediaRequest[]) => Promise<boolean | null>;
  fetchMediaHandler: (payload: FetchMediaPayload) => Promise<IMedia[]>;
  convertMediaToFile: (payload: ConvertMediaPayload) => Promise<File | null>;
}

export const useMedia = (): UseMedia => {
  const dispatch = useAppDispatch();

  const [fetchMedia] = useLazyFetchMediaQuery();
  const [uploadMedia] = useUploadMediaMutation();
  const [deleteMedia] = useDeleteMediaMutation();

  const fetchMediaHandler = async (payload: FetchMediaPayload) => {
    dispatch(MediaAC.toggleLoading(true));
    const { data: response } = await fetchMedia(payload);
    dispatch(MediaAC.toggleLoading(false));
    if (response?.data) {
      dispatch(MediaAC.setItems(response.data));
    }
    if (response?.meta) {
      dispatch(MediaAC.setPaginationMeta(response.meta));
    }
    return [];
  };

  const uploadMediaHandler = async (payload: UploadMediaPayload) => {
    const { data, error } = await uploadMedia({
      params: { collection: payload.collection, id: payload.id, modelType: payload.modelType },
      body: { 'files[]': payload.images },
    });
    if (error || !data) {
      toast.error(getApiErrorMessage(error) || 'Failed to upload media!');
      return false;
    }
    return true;
  };

  const deleteMediaHandler = async (payload: DeleteMediaRequest[]) => {
    for (const image of payload) {
      const { data, error } = await deleteMedia(image);
      if (error || !data) {
        toast.error(getApiErrorMessage(error) || 'Failed to delete media!');
        return null;
      }
    }
    return true;
  };

  const convertMediaToFile = async (payload: ConvertMediaPayload) => {
    try {
      const response = await fetch(payload.url);
      const blob = await response.blob();
      const fileName = `${payload.name}.${payload.extension}`;

      if (payload.withDownload) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('style', 'display: none');
        link.setAttribute('download', fileName);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      return new File([blob], fileName, {
        type: payload.mimeType,
        lastModified: Date.now(),
      });
    } catch (error) {
      return null;
    }
  };

  return {
    deleteMediaHandler,
    uploadMediaHandler,
    fetchMediaHandler,
    convertMediaToFile,
  };
};
