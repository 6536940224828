import { useEffect } from 'react';

import { batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PUBLIC_PAGES } from 'general/constants';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import { setNecsAccount } from 'modules/necs';

import {
  useConfirmEnableTwoFaAppMutation,
  useConfirmEnableTwoFaEmailMutation,
  useDisableTwoFaAppMutation,
  useLazyCheckSessionQuery,
  useLazyEnableTwoFaAppQuery,
  useLazyGetCSRFCookieQuery,
  useLazyLogoutQuery,
  useLazyResetTwoFaAppQuery,
  useLoginMutation,
  useSignupMutation,
  useVerifyOtpMutation,
} from '../api/auth-session.api';
import { setCurrentUser, setIsLoading } from '../store/auth-session.slice';
import {
  EnableTwoFaAppResponse,
  IUser,
  LoginPayload,
  LoginResponse,
  ResetTwoFaPayload,
  SignupPayload,
  VerifyOtpPayload,
} from '../types/auth-session.types';

interface UseAuthSession {
  loginHandler: (payload: LoginPayload) => Promise<LoginResponse | null>;
  signupHandler: (payload: SignupPayload) => Promise<boolean>;
  logoutHandler: () => Promise<void>;
  getCSRFHandler: () => Promise<void>;
  checkSessionHandler: () => Promise<IUser | null>;
  handleCheckUserAuthLoop: (pathname: string) => Promise<void>;
  verifyOtpHandler: (payload: VerifyOtpPayload) => Promise<boolean>;
  enableTwoFaAppHandler: () => Promise<EnableTwoFaAppResponse | null>;
  confirmEnableTwoFaAppHandler: (payload: VerifyOtpPayload) => Promise<boolean>;
  confirmEnableTwoFaEmailHandler: (payload: VerifyOtpPayload) => Promise<boolean>;
  disableTwoFaAppHandler: (payload: VerifyOtpPayload) => Promise<boolean>;
  resetTwoFaAppHandler: (payload: ResetTwoFaPayload) => Promise<boolean>;
}

export const useAuthSession = (): UseAuthSession => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [signupQuery] = useSignupMutation();
  const [loginQuery] = useLoginMutation();
  const [logoutQuery] = useLazyLogoutQuery();
  const [getCSRFQuery] = useLazyGetCSRFCookieQuery();
  const [checkSessionQuery] = useLazyCheckSessionQuery();
  const [verifyOtpQuery] = useVerifyOtpMutation();
  const [enableTwoFaAppQuery] = useLazyEnableTwoFaAppQuery();
  const [confirmEnableTwoFaAppQuery] = useConfirmEnableTwoFaAppMutation();
  const [confirmEnableTwoFaEmailQuery] = useConfirmEnableTwoFaEmailMutation();
  const [disableTwoFaAppQuery] = useDisableTwoFaAppMutation();
  const [resetTwoFaAppQuery] = useLazyResetTwoFaAppQuery();

  const resetTwoFaAppHandler = async (payload: ResetTwoFaPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await resetTwoFaAppQuery(payload);
    dispatch(setIsLoading(false));
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to reset 2FA');
      return false;
    }
    toast.success('2FA successfully resets');
    return true;
  };

  const confirmEnableTwoFaEmailHandler = async (payload: VerifyOtpPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await confirmEnableTwoFaEmailQuery(payload);
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to confirm 2fa email');
      dispatch(setIsLoading(false));
      return false;
    }
    await checkSessionHandler();
    dispatch(setIsLoading(false));
    toast.success('2FA successfully switched to email');
    return true;
  };

  const disableTwoFaAppHandler = async (payload: VerifyOtpPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await disableTwoFaAppQuery(payload);
    dispatch(setIsLoading(false));
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to disable 2fa app');
      return false;
    }
    toast.success('2FA APP verified. Check your email for the verification code.');
    return true;
  };

  const confirmEnableTwoFaAppHandler = async (payload: VerifyOtpPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await confirmEnableTwoFaAppQuery(payload);
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to confirm 2fa app');
      dispatch(setIsLoading(false));
      return false;
    }
    await checkSessionHandler();
    dispatch(setIsLoading(false));
    toast.success('2FA APP verified');
    return true;
  };

  const enableTwoFaAppHandler = async () => {
    dispatch(setIsLoading(true));
    const { error, data: response } = await enableTwoFaAppQuery();
    dispatch(setIsLoading(false));
    if (error || !response?.data) {
      toast.error(getApiErrorMessage(error) || 'Failed to enable 2fa app');
      return null;
    }
    return response.data;
  };

  const verifyOtpHandler = async (payload: VerifyOtpPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await verifyOtpQuery(payload);
    dispatch(setIsLoading(false));
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to verify otp');
      return false;
    }
    return true;
  };

  const getCSRFHandler = async () => {
    const { error } = await getCSRFQuery();
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to get csrf');
    }
  };

  const checkSessionHandler = async () => {
    const { data: response, error } = await checkSessionQuery();
    if (error || !response?.data) {
      return null;
    }

    const currentUser = response.data;
    batch(() => {
      dispatch(setCurrentUser(currentUser));

      if (currentUser.company?.necs_account) {
        dispatch(setNecsAccount(cloneDeep(currentUser.company.necs_account)));
      } else {
        dispatch(setNecsAccount(null));
      }
    });

    return currentUser;
  };

  const checkAuthAndRedirect = async () => {
    const result = await checkSessionHandler();
    const { pathname } = window.location;
    if (result && PUBLIC_PAGES.includes(pathname)) {
      window.location.href = '/dashboard';
    }
    if (!result && PUBLIC_PAGES.includes(pathname)) {
      return;
    }
    if (!result && !PUBLIC_PAGES.includes(pathname)) {
      window.location.href = '/login';
    }
  };

  const checkAuthEventHandler = async () => {
    if (document.visibilityState === 'visible') {
      await checkAuthAndRedirect();
    }
  };

  const handleCheckUserAuthLoop = async () => {
    if (typeof window !== 'undefined') {
      window.document.addEventListener('visibilitychange', checkAuthEventHandler);
      setInterval(async () => await checkAuthAndRedirect(), 1000 * 60);
    }

    await checkAuthAndRedirect();
  };

  const loginHandler = async (payload: LoginPayload) => {
    dispatch(setIsLoading(true));
    await getCSRFHandler();
    const { error, data: response } = await loginQuery(payload);
    dispatch(setIsLoading(false));
    if (error || !response?.data) {
      toast.error(getApiErrorMessage(error) || 'Failed to login!');
      return null;
    }
    return response.data;
  };

  const signupHandler = async (payload: SignupPayload) => {
    dispatch(setIsLoading(true));
    const { error } = await signupQuery(payload);
    dispatch(setIsLoading(false));

    if (error) {
      //old backend validation
      if ('data' in error) {
        throw error.data;
      }
      toast.error(getApiErrorMessage(error) || 'Failed to signup!');
      return false;
    }
    return true;
  };

  const logoutHandler = async () => {
    const { error } = await logoutQuery();
    if (error) {
      toast.error(getApiErrorMessage(error) || 'Failed to logout!');
      return;
    }
    batch(() => {
      dispatch(setCurrentUser(null));
      dispatch(setNecsAccount(null));
      dispatch(setIsLoading(false));
    });

    history.push('/login');
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('visibilitychange', checkAuthEventHandler);
    };
  }, []);

  return {
    loginHandler,
    signupHandler,
    checkSessionHandler,
    logoutHandler,
    getCSRFHandler,
    handleCheckUserAuthLoop,
    verifyOtpHandler,
    enableTwoFaAppHandler,
    confirmEnableTwoFaAppHandler,
    confirmEnableTwoFaEmailHandler,
    disableTwoFaAppHandler,
    resetTwoFaAppHandler,
  };
};
