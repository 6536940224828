import { IOTA_MESSAGE_URL } from 'general/constants';
import * as H from 'history';

export const handleClickRdid = (rdid: string | null) => {
  if (rdid) {
    window.open(IOTA_MESSAGE_URL + rdid, '_blank');
  }
};

export const goPDEditPage = (id: string | number | null, history: H.History<unknown>) => {
  if (id) {
    history.push(`/eecs-go/eecs-pds/edit/${id}?back_url=/eecs-go/eecs-portfolio`);
  }
};
