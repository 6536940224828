import { INecsAccount } from 'modules/necs';

export enum ETwoFaMethod {
  APP = 'google',
  EMAIL = 'email',
}

export enum ETwoFaStepState {
  SETUP = 'setup',
  QR_CODE = 'qr_code',
  REMOVE = 'remove',
  CONFIRM_CODE = 'confirm_code',
}

export enum ETwoFaStepConfirmCodeState {
  ENABLE_APP = 'enable_app',
  REMOVE_APP = 'remove_app',
  CONFIRM_EMAIL = 'confirm_email',
}

export interface IUserAvatar {
  id: number;
  name: string;
  url: string;
  ext: string;
  size: number;
}

export interface IUserRole {
  id: number;
  name: string;
  slug: string;
}
export interface IUserCompany {
  id: number;
  name: string;
  is_complete_necs_flow: boolean;
  is_have_stripe_account: boolean;
  stripe_account?: string;
  necs_account?: INecsAccount;
}

export interface IUser {
  id: number;
  fullName: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  alt_phone_number: string;
  country_code: string;
  region_code: string;
  username: string;
  avatar: IUserAvatar;
  login: string;
  email: string;
  company: IUserCompany;
  uuid: string;
  roles: IUserRole[];
  hasAdminAccess?: boolean;
  hasCompanyAdminAccess?: boolean;
  hasMarketplaceAdminAccess?: boolean;
  isMarketplaceManager?: boolean;
  hasMarketplaceAccess?: boolean;
  two_factor_method: ETwoFaMethod;
  is_2fa_enabled: boolean;
}

export interface LoginPayload {
  login: string;
  password: string;
}

export interface LoginResponse {
  two_fa_method: ETwoFaMethod | null;
}

export interface SignupPayload {
  login: string;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  password: string;
  document: File | null;
  country_code: string | null;
  region_code: string | null;
  invitationCode: string;
}

export interface VerifyOtpPayload {
  code: string;
}

export interface ResetTwoFaPayload {
  user_id: number;
}

export interface EnableTwoFaAppResponse {
  qr_code: string;
  secret: string;
}
