export enum EDashboardItemType {
  PORTFOLIO = 'portfolio',
  EXCHANGE = 'exchange',
  REVENUE = 'revenue',
  EXPENSE = 'expense',
}

export interface IDashboardItemInfo {
  title: string;
  total: number | null;
  change: number | null;
  url: string;
}

export interface IDashboardItem {
  [EDashboardItemType.PORTFOLIO]: IDashboardItemInfo;
  [EDashboardItemType.EXCHANGE]: IDashboardItemInfo;
  [EDashboardItemType.REVENUE]: IDashboardItemInfo;
  [EDashboardItemType.EXPENSE]: IDashboardItemInfo;
}

export interface FetchDashboardResponse {
  [EDashboardItemType.PORTFOLIO]: Pick<IDashboardItemInfo, 'total' | 'change'>;
  [EDashboardItemType.EXCHANGE]: Pick<IDashboardItemInfo, 'total' | 'change'>;
  [EDashboardItemType.REVENUE]: Pick<IDashboardItemInfo, 'total' | 'change'>;
  [EDashboardItemType.EXPENSE]: Pick<IDashboardItemInfo, 'total' | 'change'>;
}
