import { useEffect, useState } from 'react';

import ReactOtpInput from 'react-otp-input';

import { useAppDispatch, useAppSelector } from 'redux/store/store.types';

import { setCurrentOtpInput } from 'modules/auth-session';

import css from './otp.module.sass';

const otpLength = 6;

type Props = {
  onComplete: (otp: string) => void;
};

const OtpInput = ({ onComplete }: Props) => {
  const dispatch = useAppDispatch();
  const { currentOtpInput } = useAppSelector((state) => state.authSession);
  const [otp, setOtp] = useState<string>('');

  const inputHandler = (input: string) => {
    setOtp(input);

    if (input.length === otpLength) {
      dispatch(setCurrentOtpInput(input));
      onComplete(input);
    }
  };

  useEffect(() => {
    setOtp(currentOtpInput);
  }, [currentOtpInput]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentOtpInput(''));
    };
  }, []);

  return (
    <div className={css.otpWrapper}>
      <ReactOtpInput
        key={currentOtpInput}
        value={otp}
        onChange={inputHandler}
        numInputs={otpLength}
        inputType="tel"
        containerStyle={css.otpInputWrapper}
        inputStyle={css.otpInput}
        skipDefaultStyles
        shouldAutoFocus
        renderInput={(props) => <input {...props} />}
      />
    </div>
  );
};

export default OtpInput;
