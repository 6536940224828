import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  INecsAccount,
  INecsGoAccount,
  ISearchNecsAccount,
  SaveNecsAccountPayload,
  SearchNecsAccountPayload,
  SetNecsGoAccountsPayload,
  UpdateAccountSettingPayload,
} from '../types/necs-account.types';

export const necsAccountApi = createApi({
  reducerPath: 'necsAccountApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/account`),
  endpoints: (builder) => ({
    searchByOrganizationName: builder.query<ApiResponse<ISearchNecsAccount[]>, SearchNecsAccountPayload>({
      query: (payload) => ({
        url: `/search`,
        method: 'GET',
        params: payload,
      }),
    }),

    getGoAccounts: builder.mutation<ApiResponse<INecsGoAccount>, any>({
      query: () => ({
        url: `/goAccounts`,
        method: 'GET',
      }),
    }),

    setGoAccounts: builder.mutation<ApiResponse<any>, SetNecsGoAccountsPayload>({
      query: (payload) => ({
        url: `/goAccounts`,
        method: 'POST',
        body: payload,
      }),
    }),

    saveAccount: builder.mutation<ApiResponse<INecsAccount>, SaveNecsAccountPayload>({
      query: (payload) => ({
        url: `/connect`,
        method: 'POST',
        body: payload,
      }),
    }),

    updateAccountSettings: builder.mutation<ApiResponse<any>, UpdateAccountSettingPayload>({
      query: (payload) => ({
        url: `/update`,
        method: 'PUT',
        body: payload,
      }),
    }),

    disconnectAccount: builder.mutation<ApiResponse<any>, any>({
      query: () => ({
        url: `/disconnect`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useSaveAccountMutation,
  useDisconnectAccountMutation,
  useGetGoAccountsMutation,
  useSetGoAccountsMutation,
  useUpdateAccountSettingsMutation,
  useLazySearchByOrganizationNameQuery,
} = necsAccountApi;
