import { ApiGetRequest } from 'types';

import { IExchangeListingItem } from 'modules/necs/common';

export enum ENecsForwardModals {
  ADD_EDIT = 'add_edit',
  CONFIRM = 'confirm',
  DETAILS = 'details',
  EXCHANGE = 'exchange',
}

export enum ENecsForwardModalAction {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  CLONE = 'clone',
}

export enum ENecsForwardType {
  CREATED = 'created',
  ACQUIRED = 'acquired',
  SCHEDULED = 'scheduled',
}

export enum ENecsForwardStatus {
  IN_PROGRESS = 'in_progress',
  NO_DELIVERY = 'no_delivery',
  NO_PAYMENT = 'no_payment',
  TERMINATED = 'terminated',
  FULFILLED = 'fulfilled',
}
export interface IForwardDelivery {
  id: number;
  volume: string | number;
  date: string;
  transaction_id?: string | null;
}
export interface INecsForward {
  id: number;
  type_of_installation_code: string;
  type_of_installation_path: string[];
  energy_source_code: string;
  energy_source_code_path: string[];
  volume: number;
  delivery_started_at: string;
  delivery_completed_at: string;
  production_device: number | null;
  production_device_id: string | null;
  production_device_name: string | null;
  rdid: string | null;
  on_exchange_quantity: number | null;
  reserved_volume: number | null;
  completed_deliveries: number | null;
  completed_delivery_volume: number | null;
  type: ENecsForwardType;
  status: ENecsForwardStatus;
  counter_party_necs_go_account: string | null;
  counter_party_company_name: string | null;
  counter_party_necs_account_id: number | null;
  delivery_list: IForwardDelivery[];
  exchange_list: IExchangeListingItem[];
}

export interface NecsForwardModalPayload {
  action?: ENecsForwardModalAction;
}

export interface INecsForwardModal {
  type: ENecsForwardModals;
  payload?: NecsForwardModalPayload;
}

export interface FetchForwardsPayload extends ApiGetRequest {
  filter_by_has_exchanges?: boolean | null | number;
  filter_by_production_device_id?: string | null;
  filter_by_delivery_start_date?: string | null;
  filter_by_delivery_end_date?: string | null;
  filter_by_energy_source?: string | null;
  filter_by_type_of_installation?: string | null;
  filter_by_counter_party_necs_account_id?: string | null;
  filter_by_type?: string | null;
  filter_by_status?: string | null;

  order_by_production_device_name?: 'asc' | 'desc';
  order_by_volume?: 'asc' | 'desc';
  order_by_delivery_date?: 'asc' | 'desc';
  order_by_counter_party_company_name?: 'asc' | 'desc';
  order_by_type?: 'asc' | 'desc';
}

export interface AddForwardPayload {
  type_of_installation: string;
  energy_source: string;
  volume: number;
  production_device_id: string | null;
  forward_deliveries: IForwardDelivery[];
  forward_exchanges: IExchangeListingItem[];
}

export interface UpdateForwardPayload extends AddForwardPayload {
  id: number;
}

export interface CloneForwardPayload {
  id: number;
}

export interface DeleteForwardPayload {
  id: number;
}
