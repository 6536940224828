import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  getAllCountries,
  getCountryByCode,
  getRegionByCode,
  getRegionsByCountryCode,
} from '../../../general/countryRegion';
import AutoComplete from './AutoComplete';

const NibCountrySelector = ({
  clear = false,
  countryReq = false,
  regionReq = false,
  countryError = false,
  countryHelperText = false,
  regionHelperText = false,
  initialValues = false,
  useRegion = true,
  hideCountry = false,
  onBlurCon = () => {},
  onBlurReg = () => {},
  onChange = () => {},
  ...props
}) => {
  let [inited, setInited] = useState(false);
  let [selectedCountry, setSelectedCountry] = useState(null);
  let [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    if (clear) {
      setSelectedCountry(null);
      setSelectedRegion(null);
    }
  }, [clear]);

  useEffect(() => {
    if (!inited && initialValues && initialValues.country) {
      setSelectedCountry(getCountryByCode(initialValues.country));
      if (initialValues.region) {
        let regions = getRegionsByCountryCode(initialValues.country);
        let region = regions.filter((region) => region.code === initialValues.region);
        setSelectedRegion(region && region.length >= 1 ? region[0] : null);
      }
      setInited(true);
    }
  }, [initialValues]);

  useEffect(() => {
    onChange({
      country: selectedCountry,
      region: selectedRegion,
    });
  }, [selectedCountry, selectedRegion]);
  return (
    <>
      {!hideCountry && (
        <AutoComplete
          onBlur={onBlurCon}
          error={countryError}
          helperText={countryHelperText}
          label="Choose a country"
          options={getAllCountries()}
          onChange={(val) => setSelectedCountry(val)}
          value={selectedCountry}
          required={countryReq}
          useFlag
          {...props}
        />
      )}
      {selectedCountry && useRegion && (
        <AutoComplete
          onBlur={onBlurReg}
          label="Choose a Region"
          options={getRegionsByCountryCode(selectedCountry.code)}
          onChange={(val) => setSelectedRegion(val)}
          value={selectedRegion}
          required={regionReq}
          helperText={regionHelperText}
          {...props}
        />
      )}
    </>
  );
};

export default NibCountrySelector;
