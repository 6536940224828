import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
/* Material UI */
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const NibPasswordInput = ({
  onChange,
  value,
  label = 'Password',
  placeholder = 'password',
  size = 'medium',
  className = {},
  classes = {},
  required = false,
  error = false,
}) => {
  let [localType, setLocalType] = useState('password');
  return (
    <TextField
      onChange={(e) => onChange(e.target.value)}
      required={required}
      error={error}
      value={value}
      label={label}
      type={localType}
      size={size}
      placeholder={placeholder}
      className={className}
      variant={'outlined'}
      classes={classes}
      InputProps={{
        endAdornment: (
          <>
            <IconButton size={size} onClick={() => setLocalType(localType === 'text' ? 'password' : 'text')}>
              {localType === 'text' ? (
                <VisibilityOffIcon style={{ color: '#fff' }} />
              ) : (
                <VisibilityIcon style={{ color: '#fff' }} />
              )}
            </IconButton>
          </>
        ),
      }}
    />
  );
};

export default NibPasswordInput;
