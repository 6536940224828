import { batch } from 'react-redux';
import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import {
  FetchProductionDevicesPayload,
  useLazyFetchNecsProductionDevicesQuery,
} from 'modules/necs/necs-production-device';

import {
  useAddForwardMutation,
  useDeleteForwardMutation,
  useLazyCloneForwardQuery,
  useLazyFetchForwardsQuery,
  useUpdateForwardMutation,
} from '../api/necs-forward.api';
import { NecsForwardAC, setNecsForwardModal, setSelectedNecsForward } from '../store/necs-forward.slice';
import {
  AddForwardPayload,
  CloneForwardPayload,
  DeleteForwardPayload,
  FetchForwardsPayload,
  INecsForward,
  INecsForwardModal,
  UpdateForwardPayload,
} from '../types/necs-forward.types';

interface OpenModal {
  modal: INecsForwardModal;
  forward: INecsForward | null;
}

interface UseNecsForward {
  clearNecsForwardState: () => void;
  openNecsForwardsModal: (payload: OpenModal) => void;
  closeNecsForwardModal: () => void;
  fetchProductionDevicesOptionsHandler: (
    payload: FetchProductionDevicesPayload,
  ) => Promise<Array<{ id: number | string; name: string }>>;

  fetchNecsForwardsHandler: (payload: FetchForwardsPayload) => Promise<boolean>;
  addForwardHandler: (payload: AddForwardPayload) => Promise<boolean>;
  updateForwardHandler: (payload: UpdateForwardPayload) => Promise<boolean>;
  deleteForwardHandler: (payload: DeleteForwardPayload) => Promise<boolean>;
  cloneForwardHandler: (payload: CloneForwardPayload) => Promise<INecsForward | null>;
}

export const useNecsForward = (): UseNecsForward => {
  const dispatch = useAppDispatch();

  const [fetchProductionDevicesQuery] = useLazyFetchNecsProductionDevicesQuery();
  const [fetchForwardsQuery] = useLazyFetchForwardsQuery();
  const [addForwardQuery] = useAddForwardMutation();
  const [updateForwardQuery] = useUpdateForwardMutation();
  const [deleteForwardQuery] = useDeleteForwardMutation();
  const [cloneForwardQuery] = useLazyCloneForwardQuery();

  const fetchNecsForwardsHandler = async (payload: FetchForwardsPayload) => {
    dispatch(NecsForwardAC.toggleLoading(true));
    const { data: response, error } = await fetchForwardsQuery(payload);
    dispatch(NecsForwardAC.toggleLoading(false));
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Forwards!');
    } else {
      batch(() => {
        dispatch(NecsForwardAC.clearItems());
        dispatch(NecsForwardAC.setItems(response.data));
        dispatch(NecsForwardAC.setPaginationMeta(response.meta));
      });
    }

    return !isError;
  };

  const cloneForwardHandler = async (payload: CloneForwardPayload) => {
    dispatch(NecsForwardAC.toggleLoading(true));
    const { data: response, error } = await cloneForwardQuery(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      dispatch(NecsForwardAC.toggleLoading(false));
      toast.error(getApiErrorMessage(error) || 'Failed to clone NECS Forward!');
      return null;
    } else {
      await fetchNecsForwardsHandler({ page: 1, per_page: 25 });
      toast.success('Forward successfully cloned!');
    }

    return response.data;
  };

  const addForwardHandler = async (payload: AddForwardPayload) => {
    dispatch(NecsForwardAC.toggleLoading(true));
    const { data: response, error } = await addForwardQuery(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      dispatch(NecsForwardAC.toggleLoading(false));
      toast.error(getApiErrorMessage(error) || 'Failed to add NECS Forward!');
    } else {
      await fetchNecsForwardsHandler({ page: 1, per_page: 25 });
      toast.success('Forward successfully added!');
    }

    return !isError;
  };

  const updateForwardHandler = async (payload: UpdateForwardPayload) => {
    dispatch(NecsForwardAC.toggleLoading(true));
    const { data: response, error } = await updateForwardQuery(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      dispatch(NecsForwardAC.toggleLoading(false));
      toast.error(getApiErrorMessage(error) || 'Failed to update NECS Forward!');
    } else {
      await fetchNecsForwardsHandler({ page: 1, per_page: 25 });
      toast.success('Forward successfully updated!');
    }

    return !isError;
  };

  const deleteForwardHandler = async (payload: DeleteForwardPayload) => {
    dispatch(NecsForwardAC.toggleLoading(true));
    const { error } = await deleteForwardQuery(payload);
    if (error) {
      dispatch(NecsForwardAC.toggleLoading(false));
      toast.error(getApiErrorMessage(error) || 'Failed to delete NECS Forward!');
    } else {
      await fetchNecsForwardsHandler({ page: 1, per_page: 25 });
      toast.success('Forward successfully deleted!');
    }

    return !error;
  };

  const fetchProductionDevicesOptionsHandler = async (payload: FetchProductionDevicesPayload) => {
    const { data: response, error } = await fetchProductionDevicesQuery(payload);
    const isError = error || !response?.data;
    if (isError) {
      return [];
    }

    return response.data.map((item) => ({ name: item.production_device_name, id: item.production_device_id }));
  };

  const openNecsForwardsModal = ({ modal, forward }: OpenModal) => {
    batch(() => {
      dispatch(setSelectedNecsForward(forward));
      dispatch(setNecsForwardModal(modal));
    });
  };

  const closeNecsForwardModal = () => {
    batch(() => {
      dispatch(setSelectedNecsForward(null));
      dispatch(setNecsForwardModal(null));
    });
  };

  const clearNecsForwardState = () => {
    batch(() => {
      dispatch(NecsForwardAC.clearItems());
      dispatch(NecsForwardAC.clearFilters());
      dispatch(NecsForwardAC.clearSorts());
      dispatch(setSelectedNecsForward(null));
      dispatch(setNecsForwardModal(null));
    });
  };

  return {
    clearNecsForwardState,
    openNecsForwardsModal,
    closeNecsForwardModal,
    fetchProductionDevicesOptionsHandler,
    fetchNecsForwardsHandler,
    addForwardHandler,
    updateForwardHandler,
    deleteForwardHandler,
    cloneForwardHandler,
  };
};
