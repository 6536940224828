import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EDashboardItemType, IDashboardItem } from '../types';

export interface NecsDashboardInitialState {
  isLoading: boolean;
  dashboard: IDashboardItem;
}

const necsDashboardInitialState: NecsDashboardInitialState = {
  isLoading: false,
  dashboard: {
    [EDashboardItemType.PORTFOLIO]: {
      title: 'Portfolio',
      total: null,
      change: null,
      url: '/eecs-go/eecs-portfolio',
    },
    [EDashboardItemType.EXCHANGE]: {
      title: 'Exchange',
      total: null,
      change: null,
      url: '/eecs-go/eecs-portfolio?hasExchanges=true',
    },
    [EDashboardItemType.REVENUE]: {
      title: 'Revenue',
      total: null,
      change: null,
      url: '/eecs-go/eecs-transactions?transactionType=sell',
    },
    [EDashboardItemType.EXPENSE]: {
      title: 'Expense',
      total: null,
      change: null,
      url: '/eecs-go/eecs-transactions?transactionType=buy',
    },
  },
};

export const necsDashboard = createSlice({
  name: 'necsDashboard',
  initialState: necsDashboardInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setNecsDashboard(state, action: PayloadAction<IDashboardItem>) {
      state.dashboard = action.payload;
    },
  },
});

export const { setIsLoading, setNecsDashboard } = necsDashboard.actions;
