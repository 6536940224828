import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { IMedia } from '../types/media.types';

export interface MediaInitialState extends BaseDataReducerV2InitialState<any> {
  isNewMedia: boolean;
}

const { initialState, AC, reducer } = basedDataReducerV2<IMedia, MediaInitialState>('media-base');

const necsInitialState: MediaInitialState = {
  ...initialState,
  perPage: 15,
  isNewMedia: false,
};

export const appMedia = createSlice({
  name: 'media',
  initialState: necsInitialState,
  reducers: {
    setIsNewMedia: (state, action: PayloadAction<boolean>) => {
      state.isNewMedia = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as MediaInitialState, action);
    });
  },
});
export const MediaAC = AC;

export const setMediaCurrentPage = AC.setCurrentPage;

export const { setIsNewMedia } = appMedia.actions;
