import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getUserFullName } from 'general/helpers';

import { ETwoFaStepConfirmCodeState, ETwoFaStepState, IUser } from '../types/auth-session.types';

export interface AuthSessionInitialState {
  isLoading: boolean;
  currentUser: IUser | null;
  twoFaStepState: ETwoFaStepState | null;
  twoFaStepConfirmCodeState: ETwoFaStepConfirmCodeState | null;
  currentOtpInput: string;
}

const authSessionInitialState: AuthSessionInitialState = {
  currentUser: null,
  isLoading: false,
  twoFaStepConfirmCodeState: null,
  twoFaStepState: null,
  currentOtpInput: '',
};

export const authSession = createSlice({
  name: 'authSession',
  initialState: authSessionInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    setCurrentOtpInput(state, action: PayloadAction<string>) {
      state.currentOtpInput = action.payload;
    },

    setTwoFaStepState(state, action: PayloadAction<ETwoFaStepState | null>) {
      state.twoFaStepState = action.payload;
    },

    setTwoFaStepConfirmCodeState(state, action: PayloadAction<ETwoFaStepConfirmCodeState | null>) {
      state.twoFaStepConfirmCodeState = action.payload;
    },

    setCurrentUser(state, action: PayloadAction<IUser | null>) {
      if (!action.payload) {
        state.currentUser = null;
        return;
      }

      let user = { ...action.payload };
      if (user.roles) {
        user.hasAdminAccess = user.roles.map((role) => role.slug.toLowerCase()).includes('admin');
        user.hasCompanyAdminAccess = user.roles.map((role) => role.slug.toLowerCase()).includes('company_admin');
        user.hasMarketplaceAdminAccess = user.roles
          .map((role) => role.slug.toLowerCase())
          .includes('marketplace_admin');
        user.isMarketplaceManager = user.roles.map((role) => role.slug.toLowerCase()).includes('marketplace_manager');

        let marketplaceAccess = false;
        for (let role of user.roles)
          if (role.slug.toLowerCase().indexOf('marketplace') > -1 || role.slug.toLowerCase() === 'admin') {
            marketplaceAccess = true;
            break;
          }
        user.hasMarketplaceAccess = marketplaceAccess;
      } else {
        user.roles = [];
      }
      user.fullName = getUserFullName(user);
      state.currentUser = user;
    },
  },
});

export const { setCurrentUser, setIsLoading, setTwoFaStepState, setTwoFaStepConfirmCodeState, setCurrentOtpInput } =
  authSession.actions;
