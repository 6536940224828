import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { INecsWhiteList, INecsWhiteListModal } from '../types';

export interface InitialState extends BaseDataReducerV2InitialState<INecsWhiteList> {
  currentModal: INecsWhiteListModal | null;
  selectedWhiteList: INecsWhiteList | null;
}

const { initialState, AC, reducer } = basedDataReducerV2<INecsWhiteList, InitialState>('necs-white-list');

const necsInitialState: InitialState = {
  ...initialState,
  currentModal: null,
  selectedWhiteList: null,
};

export const necsWhiteList = createSlice({
  name: 'necsWhiteList',
  initialState: necsInitialState,
  reducers: {
    setNecsSelectedWhiteList: (state, action: PayloadAction<INecsWhiteList | null>) => {
      state.selectedWhiteList = action.payload;
    },
    setNecsWhiteListModal: (state, action: PayloadAction<INecsWhiteListModal | null>) => {
      state.currentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as InitialState, action);
    });
  },
});
export const NecsWhiteListAC = AC;

export const { setNecsWhiteListModal, setNecsSelectedWhiteList } = necsWhiteList.actions;
