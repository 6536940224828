import { useHistory, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'redux/store/store.types';

import { ETwoFaMethod, setCurrentOtpInput, useAuthSession } from 'modules/auth-session';

import { Typography } from '@material-ui/core';

import NibButton from 'components/common/NibButton/NibButton';
import NibLoader from 'components/common/NibLoader/NibLoader';
import OtpInput from 'components/common/OtpInput';

import css from './loginPage.module.sass';

type Props = {
  twoFaMethod: ETwoFaMethod | null;
  onCancel: () => void;
};

const OtpForm = ({ twoFaMethod, onCancel }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const returnUrl = new URLSearchParams(useLocation().search).get('return');

  const { isLoading } = useAppSelector((store) => store.authSession);
  const { verifyOtpHandler, checkSessionHandler } = useAuthSession();

  const otpHandler = async (otp: string) => {
    const isVerified = await verifyOtpHandler({ code: otp });
    if (isVerified) {
      await checkSessionHandler();
      returnUrl ? (window.location.href = returnUrl) : history.push('/dashboard');
    } else {
      dispatch(setCurrentOtpInput(''));
    }
  };

  return (
    <div className={css.main}>
      <div className={classNames(css.block, css.otpBlock)}>
        <div className={css.logo}></div>
        <div className={css.loginInfo}>
          <Typography className={css.signIn}>Two-Factor Authentication (2FA)</Typography>
          {twoFaMethod === ETwoFaMethod.EMAIL && (
            <Typography className={css.otpInfo}>
              Please enter the 6-digit code that was sent to your <strong>email address.</strong>
            </Typography>
          )}
          {twoFaMethod === ETwoFaMethod.APP && (
            <Typography className={css.otpInfo}>
              Please enter the 6-digit code from your <strong>Authenticator app</strong>.
            </Typography>
          )}
        </div>
        {isLoading && <NibLoader />}
        <OtpInput onComplete={otpHandler} />
        <div className="flex">
          {/*  @ts-ignore */}
          <NibButton
            className={`n-button-big-red-secondary`}
            onClick={onCancel}
            label="CANCEL"
            style={{ marginLeft: 'auto' }}
          />
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
