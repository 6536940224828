import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { analyzeImage } from '@helpers';
import { routerLinksSelector } from '@redux/router-reducer';
import classnames from 'classnames';
import { useAppSelector } from 'redux/store/store.types';

import { List } from '@material-ui/core';
import { NotificationsNone } from '@material-ui/icons';

import SideBarItem from './SideBarItem';
import css from './appNav.module.sass';

const SideBar = ({ open, getAvatarSrc, userRolesText }) => {
  const history = useHistory();
  const location = useLocation();

  const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);
  const navLinks = useSelector(routerLinksSelector);

  const { currentUser } = useAppSelector((store) => store.authSession);
  const { unread_channels } = useSelector(({ session }) => session);

  const [activePage, setActive] = useState('/');
  const [bgColor, setBgColor] = useState('black');

  const haveRights = (nl) => {
    for (let role of currentUser.roles) {
      if (nl.roles_allowed && nl.roles_allowed.includes(role.slug)) return true;
    }
    return false;
  };

  useEffect(() => {
    if (location.pathname !== activePage) {
      setActive(location.pathname);
    }
  }, [location]);

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={classnames(css.sideBar, open && css.open)}>
      <div className={css.userBlock}>
        <div className={css.userAvatar}>
          <div className={css.userAvatarImage} style={{ backgroundColor: bgColor }}>
            <img
              src={getAvatarSrc()}
              alt="profile_logo"
              crossOrigin="anonymous"
              onLoad={(e) => {
                const brightness = analyzeImage(e.target);
                brightness !== null && setBgColor(brightness < 90 ? 'black' : '#D3D3D3');
              }}
            />
          </div>
          <div className={css.userEditButton} onClick={() => history.push('/user-profile')}></div>
        </div>
        <div className={css.userName}>
          <div>Hi, {currentUser.fullName}</div>
          <div className={css.userRolesText}>{userRolesText}</div>
        </div>
      </div>

      <div style={{ width: '100%', height: '100%' }} className={css.DesktopMenuScrollbars}>
        {navLinks.map((nl) => {
          let IconComponent = null;
          if (nl.label === 'Communication' && unread_channels && unread_channels.length)
            IconComponent = NotificationsNone;

          if (nl.alwaysHide) return null;

          if (nl.disabled) return null;

          if ((!nl.hide && (nl.unprotected || currentUser.hasAdminAccess || rolesDisabled)) || haveRights(nl))
            return (
              <SideBarItem
                key={nl.id}
                className={activePage.includes(nl.to) && css.active}
                active={activePage.includes(nl.to)}
                location={location}
                onClick={() => setActive(nl.to)}
                {...{ ...nl, IconComponent }}
              />
            );
        })}
      </div>
    </List>
  );
};

export default SideBar;
