import { TABLE_PAGE_SIZE } from 'general/constants';

import API from '../api/api';
import { getBackendUrl } from '../general/helpers';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';

const { iniState, reducer, ACTIONS, AC, helpers, thunks } = baseDataReducer('documentsList');

const SET_TYPES = '/nibchain/documents/SET_TYPES';
const SET_DOC_TO_EDITED = '/nibchain/documents/SET_DOC_TO_EDITED';
const SET_DOC_TO_ADDED = '/nibchain/documents/SET_DOC_TO_ADDED';
const SET_DOC_TO_REMOVED = '/nibchain/documents/SET_DOC_TO_REMOVED';
const CLEAR_CHANGES = '/nibchain/documents/CLEAR_CHANGES';

const initialState = {
  ...iniState,
  initial_items: [],
  items: [],
  total: 0,
  types: [],
  editedDocs: [],
  addedDocs: [],
  removedDocs: [],
  filters: {
    search: '',
  },
};

const documentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ITEMS:
      return {
        ...state,
        items: [
          ...state.items,
          ...action.payload.map((item) => ({ ...item, file_url: getBackendUrl(`/document/file/get?id=${item.id}`) })),
        ],
        initial_items: [
          ...state.items,
          ...action.payload.map((item) => ({ ...item, file_url: getBackendUrl(`/document/file/get?id=${item.id}`) })),
        ],
      };
    case SET_TYPES:
      return {
        ...state,
        types: [...action.payload],
      };
    case SET_DOC_TO_EDITED:
      return {
        ...state,
        editedDocs: [...state.editedDocs, action.payload],
      };
    case SET_DOC_TO_ADDED:
      return {
        ...state,
        addedDocs: [...state.addedDocs, action.payload],
        items: [...state.items, action.payload],
      };
    case SET_DOC_TO_REMOVED:
      return {
        ...state,
        removedDocs: [...state.removedDocs, action.payload],
      };
    case CLEAR_CHANGES:
      return {
        ...state,
        removedDocs: [],
        addedDocs: [],
        editedDocs: [],
      };
    case ACTIONS.CLEAR_ITEMS_AND_TOTAL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return reducer(state, action);
  }
};

export const clearItemsAndTotal = AC.clearItemsAndTotal;

export const onDocumentsListFilter = thunks.onFilter;

export const getDocumentsList =
  ({ limit = TABLE_PAGE_SIZE, offset = 0, excludeId = 0, filters, type = 'product' }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      let documents = await API.documents.get({ limit, offset, excludeId, search: filters.search, type: type });
      dispatch(AC.setItems(documents.items));
      dispatch(AC.setTotal(documents.total));
      dispatch(AC.toggleLoading(false));
      return documents.items;
    } catch (error) {
      onError({ status: 0, message: 'Error on get documents!', deb_info: error });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export default documentsListReducer;
