import { TABLE_PAGE_SIZE } from 'general/constants';

import API from '../api/api';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';

const { AC, reducer, helpers, ACTIONS, thunks } = baseDataReducer('products');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  offset: 0,
  filters: {},
  sorting: {},
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const onProductsFilter = thunks.onFilter;
export const onProductsSort = thunks.onSort;

export const setProductsOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const getProductTypes = async (offset = 0, limit = 20) => {
  let data = await API.productTypes.get({ offset, limit });
  return data.items || [];
};

export const toggleLoading = (status) => (dispatch) => {
  dispatch(AC.toggleLoading(status));
};

export const getProductsByNamePattern = (namePattern) => async (dispatch) => {
  let data = await API.products.get({ name: namePattern });
  return data.items;
};

export const getProductsByNameAndCompany = (company_id) => async (namePattern) => {
  if (!company_id || !namePattern) {
    return [];
  }
  let data = await API.products.getByCompanyAndID({ name: namePattern, company_id });
  return data.items;
};

export const getProducts =
  ({
    offset = 0,
    limit = TABLE_PAGE_SIZE,
    endDate,
    startDate,
    company_id,
    search,
    active,
    order_date,
    order_sku,
    order_company,
  }) =>
  async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let data = await API.products.get({
      offset,
      limit,
      endDate,
      startDate,
      company_id,
      search,
      active,
      order_date,
      order_sku,
      order_company,
    });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
  };

export const removeProduct = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.products.remove({ id });
    dispatch(AC.toggleInit(false));
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on remove product!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const editProduct = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      getID: ['company', 'type'],
      getBackEndDate: ['createdAt'],
      getBackEndDateTime: ['start_date', 'end_date', 'production_date'],
      remove: ['new_sponsors_images', 'sponsors_images'],
      toJSON: ['sponsors_images_to_remove'],
      toMulti: ['allergens', 'attributes', 'tags'],
    });
    if (dataToSend.sub_suppliers) {
      dataToSend.sub_suppliers = JSON.stringify(
        data.sub_suppliers.map((company) => ({ id: company.id, submit_to_api: company.submit_to_api })),
      );
    }
    let status = await API.products.edit(dataToSend);
    dispatch(AC.editItem(status));
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit product!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const addProduct = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      getID: ['company'],
      getBackEndDateTime: ['start_date', 'end_date', 'production_date'],
      remove: ['new_sponsors_images', 'sponsors_images', 'sponsors_images_to_remove'],
      toMulti: ['allergens', 'attributes', 'tags'],
    });
    if (dataToSend.sub_suppliers && dataToSend.sub_suppliers.length !== 0) {
      dataToSend.sub_suppliers = JSON.stringify(
        dataToSend.sub_suppliers.map((company) => ({ id: company.id, submit_to_api: company.submit_to_api })),
      );
    }
    let status = await API.products.add(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on add product!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const clearProducts = () => (dispatch) => {
  dispatch(AC.clearItems());
};

export const clearProductsAndTotal = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getOneProduct = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let product = await API.products.getOneById({ id });
    dispatch(AC.toggleLoading(false));
    return product;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on get product!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default productsReducer;
