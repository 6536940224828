import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import { useLazyFetchNecsTransactionsQuery, useUpdateNecsTransactionsMutation } from '../api/necs-transaction.api';
import {
  NecsTransactionAC,
  setNecsTransactionModal,
  setSelectedNecsTransaction,
} from '../store/necs-transaction.slice';
import {
  FetchNecsTransactionPayload,
  INecsTransaction,
  INecsTransactionModal,
  UpdateNecsTransactionPayload,
} from '../types/necs-transaction.type';

interface UseNecsTransaction {
  fetchNecsTransactionsHandler: (payload: FetchNecsTransactionPayload) => Promise<boolean>;
  updateNecsTransactionsHandler: (payload: UpdateNecsTransactionPayload) => Promise<boolean>;
  openNecsTransactionsModal: ({ modal, transaction }: OpenModal) => void;
  clearNecsTransactionsState: () => void;
  closeNecsTransactionsModal: () => void;
}

interface OpenModal {
  modal: INecsTransactionModal;
  transaction: INecsTransaction;
}

export const useNecsTransaction = (): UseNecsTransaction => {
  const dispatch = useAppDispatch();
  const [fetchNecsTransactionsQuery] = useLazyFetchNecsTransactionsQuery();
  const [updateNecsTransactionsQuery] = useUpdateNecsTransactionsMutation();

  const fetchNecsTransactionsHandler = async (payload: FetchNecsTransactionPayload) => {
    dispatch(NecsTransactionAC.toggleLoading(true));
    const { data: response, error } = await fetchNecsTransactionsQuery(payload);
    dispatch(NecsTransactionAC.toggleLoading(false));

    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Transactions!');
    } else {
      dispatch(NecsTransactionAC.clearItems());
      dispatch(NecsTransactionAC.setItems(response.data));
      dispatch(NecsTransactionAC.setPaginationMeta(response.meta));
    }

    return !isError;
  };

  const updateNecsTransactionsHandler = async (payload: UpdateNecsTransactionPayload) => {
    dispatch(NecsTransactionAC.toggleLoading(true));
    const { data: response, error } = await updateNecsTransactionsQuery(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to update NECS Transactions!');
    } else {
      dispatch(NecsTransactionAC.editItem(response.data));
    }

    dispatch(NecsTransactionAC.toggleLoading(false));
    return !isError;
  };

  const clearNecsTransactionsState = () => {
    dispatch(NecsTransactionAC.clearItems());
    dispatch(NecsTransactionAC.clearFilters());
    dispatch(NecsTransactionAC.clearSorts());
    dispatch(setSelectedNecsTransaction(null));
    dispatch(setNecsTransactionModal(null));
  };

  const openNecsTransactionsModal = ({ modal, transaction }: OpenModal) => {
    dispatch(setSelectedNecsTransaction(transaction));
    dispatch(setNecsTransactionModal(modal));
  };

  const closeNecsTransactionsModal = () => {
    dispatch(setSelectedNecsTransaction(null));
    dispatch(setNecsTransactionModal(null));
  };

  return {
    fetchNecsTransactionsHandler,
    clearNecsTransactionsState,
    closeNecsTransactionsModal,
    openNecsTransactionsModal,
    updateNecsTransactionsHandler,
  };
};
