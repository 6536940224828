import { useState } from 'react';

/* Utils & Libs */
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Bg } from '@assets/img/menu/menu-hover.svg';
import classnames from 'classnames';
import { useAppSelector } from 'redux/store/store.types';

import Collapse from '@material-ui/core/Collapse';
/* Material UI */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

/* Styles */
import css from './appNav.module.sass';

const SideBarItem = ({
  to,
  label,
  location,
  onClick = () => {},
  sub = [],
  active = false,
  className,
  IconComponent,
  ...props
}) => {
  const history = useHistory();
  let [subExpanded, setSubExpanded] = useState(false);

  const isSub = sub && Array.isArray(sub) && sub.length;

  const { currentUser } = useAppSelector((store) => store.authSession);

  const haveRights = (nl) => {
    for (let role of currentUser.roles) {
      if (nl.roles_allowed && nl.roles_allowed.includes(role.slug)) return true;
    }
    return false;
  };

  const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);

  if (history.location.pathname.indexOf(to) === 0) {
    subExpanded = true;
  }

  return (
    <>
      {isSub ? (
        <ListItem
          button
          onClick={() => setSubExpanded(!subExpanded)}
          className={classnames(css.item, css.withSub, className ? className : '')}
        >
          {active && <Bg />}
          <div className={css.itemIconBlock}>
            <div className={css.itemIconImage} style={{ backgroundImage: `url(${props.icon})` }}></div>
          </div>
          <p>{label}</p>

          {subExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      ) : (
        <ListItem button className={classnames(css.item, className ? className : '')}>
          <NavLink to={to} onClick={onClick}>
            {active && <Bg />}
            <div className={css.itemIconBlock} onClick={onClick}>
              <div
                className={css.itemIconImage}
                style={{
                  backgroundImage: `url(${props.icon})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              ></div>
            </div>
            <p>{label}</p>
            {IconComponent && <IconComponent className={css.icon} />}
          </NavLink>
        </ListItem>
      )}

      {isSub ? (
        <Collapse in={subExpanded} timeout="auto" unmountOnExit>
          <List component="div" className={css.subMenu} disablePadding>
            {sub.map((s) => {
              if ((!s.hide && (s.unprotected || currentUser.hasAdminAccess || rolesDisabled)) || haveRights(s))
                return (
                  <ListItem key={s.id} className={location.pathname == s.to && css.subItem}>
                    <NavLink to={s.to} onClick={s.onClick}>
                      <div></div>
                      {s.label}
                    </NavLink>
                  </ListItem>
                );
            })}
          </List>
        </Collapse>
      ) : (
        ''
      )}
    </>
  );
};

export default SideBarItem;
