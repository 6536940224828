import { useState } from 'react';

import { ETwoFaMethod } from 'modules/auth-session';

import LoginForm from './LoginForm';
import OtpForm from './OtpForm';
import css from './loginPage.module.sass';

const LoginPage = () => {
  const [twoFaMethod, setTwoFaMethod] = useState<ETwoFaMethod | null>(null);
  return (
    <div className={css.content}>
      {twoFaMethod ? (
        <OtpForm onCancel={() => setTwoFaMethod(null)} twoFaMethod={twoFaMethod} />
      ) : (
        <LoginForm onTwoFa={setTwoFaMethod} />
      )}
    </div>
  );
};

export default LoginPage;
