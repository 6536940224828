import { useCallback } from 'react';

import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import { useLazyFetchNecsDashboardQuery } from '../api/necs-dashboard.api';
import { setIsLoading, setNecsDashboard } from '../store/necs-dashboard.slice';
import { EDashboardItemType } from '../types';

interface UseNecsDashboard {
  fetchNecsDashboardHandler: () => void;
}

export const useNecsDashboard = (): UseNecsDashboard => {
  const dispatch = useAppDispatch();
  const { dashboard } = useAppSelector((store) => store.necsDashboard);
  const [fetchNecsDashboardQuery] = useLazyFetchNecsDashboardQuery();

  const fetchNecsDashboardHandler = useCallback(async () => {
    dispatch(setIsLoading(true));
    const { data: response, error } = await fetchNecsDashboardQuery({});
    dispatch(setIsLoading(false));
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Dashboard!');
      return;
    }

    const updatedDashboard = { ...dashboard };

    Object.keys(updatedDashboard).forEach((key) => {
      if (response.data[key as EDashboardItemType]) {
        updatedDashboard[key as EDashboardItemType] = {
          ...updatedDashboard[key as EDashboardItemType],
          ...response.data[key as EDashboardItemType],
        };
      }
    });

    dispatch(setNecsDashboard(updatedDashboard));
  }, [dashboard]);

  return {
    fetchNecsDashboardHandler,
  };
};
