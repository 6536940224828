import { useHistory } from 'react-router-dom';

import css from './welcomePage.module.sass';

const WelcomePage = () => {
  let history = useHistory();

  return <div className={css.wrap} onClick={() => history.push('/login')}></div>;
};

export default WelcomePage;
