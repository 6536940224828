import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { ProductionDevice } from '../types/necs-production-device.types';

export interface NecsProductionDevicesInitialState extends BaseDataReducerV2InitialState<ProductionDevice> {
  perPage: number;
  editingDevice: ProductionDevice | null;
}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<ProductionDevice, NecsProductionDevicesInitialState>(
  'necs-production-devices',
);

export const necsProductionDevicesInitialState: NecsProductionDevicesInitialState = {
  ...initialState,
  perPage: 25,
  editingDevice: null,
  filters: {
    hasCertificates: 1,
  },
};

export const necsProductionDevice = createSlice({
  name: 'necsProductionDevice',
  initialState: necsProductionDevicesInitialState,
  reducers: {
    setEditingProductionDevice: (state, action: PayloadAction<ProductionDevice | null>) => {
      state.editingDevice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsProductionDevicesInitialState, action);
    });
  },
});

export const ProductionDeviceAC = AC;
export const onProductionDeviceSort = thunks.onSort;
export const onProductionDeviceFilter = thunks.onFilter;
export const clearProductionDeviceFilters = AC.clearFilters;
export const setProductionDeviceCurrentPage = AC.setCurrentPage;

export const { setEditingProductionDevice } = necsProductionDevice.actions;
