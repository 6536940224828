import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import {
  ENecsTransactionType,
  ETransactionStatus,
  INecsTransaction,
  INecsTransactionModal,
} from '../types/necs-transaction.type';

export interface NecsTransactionInitialState extends BaseDataReducerV2InitialState<INecsTransaction> {
  perPage: number;
  selectedTransaction: INecsTransaction | null;
  transactionTypes: Array<{ id: ENecsTransactionType; name: string }>;
  transactionStatuses: Array<{ id: ETransactionStatus; name: string }>;
  currentModal: INecsTransactionModal | null;
}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<INecsTransaction, NecsTransactionInitialState>(
  'necs-transaction',
);

export const necsTransactionInitialState: NecsTransactionInitialState = {
  ...initialState,
  perPage: 25,
  selectedTransaction: null,
  currentModal: null,
  transactionTypes: [
    { id: ENecsTransactionType.BUY, name: 'Buy' },
    { id: ENecsTransactionType.SELL, name: 'Sell' },
  ],
  transactionStatuses: [
    { id: ETransactionStatus.PENDING, name: 'Pending' },
    { id: ETransactionStatus.COMPLETED, name: 'Completed' },
    { id: ETransactionStatus.CANCELLED, name: 'Cancelled' },
    { id: ETransactionStatus.ON_HOLD, name: 'On Hold' },
    { id: ETransactionStatus.UNPAID, name: 'Unpaid' },
  ],
};

export const necsTransaction = createSlice({
  name: 'necsTransaction',
  initialState: necsTransactionInitialState,
  reducers: {
    setSelectedNecsTransaction: (state, action: PayloadAction<INecsTransaction | null>) => {
      state.selectedTransaction = action.payload;
    },
    setNecsTransactionModal: (state, action: PayloadAction<INecsTransactionModal | null>) => {
      state.currentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsTransactionInitialState, action);
    });
  },
});

export const NecsTransactionAC = AC;
export const onNecsTransactionSort = thunks.onSort;
export const onNecsTransactionFilter = thunks.onFilter;
export const clearNecsTransactionFilters = AC.clearFilters;
export const setNecsTransactionCurrentPage = AC.setCurrentPage;

export const { setSelectedNecsTransaction, setNecsTransactionModal } = necsTransaction.actions;
