import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { IGoCertificate, INecsCertificateModal } from '../types/necs-go-certificate.type';

export interface NecsGoCertificateInitialState extends BaseDataReducerV2InitialState<IGoCertificate> {
  currentModal: INecsCertificateModal | null;
  selectedCertificate: IGoCertificate | null;
}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<IGoCertificate, NecsGoCertificateInitialState>(
  'necs-go-certificate',
);

export const necsGoCertificateInitialState: NecsGoCertificateInitialState = {
  ...initialState,
  perPage: 25,
  currentModal: null,
  selectedCertificate: null,
};

export const necsGoCertificate = createSlice({
  name: 'necsGoCertificate',
  initialState: necsGoCertificateInitialState,
  reducers: {
    setNecsCertificateModal: (state, action: PayloadAction<INecsCertificateModal | null>) => {
      state.currentModal = action.payload;
    },
    setSelectedNecsCertificate: (state, action: PayloadAction<IGoCertificate | null>) => {
      state.selectedCertificate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsGoCertificateInitialState, action);
    });
  },
});

export const GoCertificateAC = AC;
export const onGoCertificateSort = thunks.onSort;
export const onGoCertificateFilter = thunks.onFilter;
export const clearGoCertificateFilters = AC.clearFilters;
export const setGoCertificateCurrentPage = AC.setCurrentPage;

export const { setNecsCertificateModal, setSelectedNecsCertificate } = necsGoCertificate.actions;
