import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from 'types';
import { generateFormData } from 'utils';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  EnableTwoFaAppResponse,
  IUser,
  LoginPayload,
  LoginResponse,
  ResetTwoFaPayload,
  SignupPayload,
  VerifyOtpPayload,
} from '../types/auth-session.types';

export const authSessionApi = createApi({
  reducerPath: 'authSessionApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}`),
  endpoints: (builder) => ({
    getCSRFCookie: builder.query<void, void>({
      query: () => ({
        url: '/v2/sanctum/csrf-cookie',
        method: 'GET',
      }),
    }),

    login: builder.mutation<ApiResponse<LoginResponse>, LoginPayload>({
      query: (payload) => ({
        url: '/v2/login',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),

    logout: builder.query<void, void>({
      query: () => ({
        url: '/v2/logout',
        method: 'POST',
      }),
    }),

    checkSession: builder.query<ApiResponse<IUser>, void>({
      query: () => ({
        url: '/auth/getMe',
        method: 'GET',
      }),
    }),

    signup: builder.mutation<ApiResponse<IUser>, SignupPayload>({
      query: (payload) => ({
        url: '/auth/signup',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),

    verifyOtp: builder.mutation<ApiResponse<any>, VerifyOtpPayload>({
      query: (payload) => ({
        url: '/v2/2fa/verify',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),

    enableTwoFaApp: builder.query<ApiResponse<EnableTwoFaAppResponse>, void>({
      query: () => ({
        url: '/v2/2fa/google/enable',
        method: 'GET',
      }),
    }),

    confirmEnableTwoFaApp: builder.mutation<ApiResponse<EnableTwoFaAppResponse>, VerifyOtpPayload>({
      query: (payload) => ({
        url: '/v2/2fa/google/confirm',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),

    disableTwoFaApp: builder.mutation<ApiResponse<any>, VerifyOtpPayload>({
      query: (payload) => ({
        url: '/v2/2fa/email/request-switch',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),

    resetTwoFaApp: builder.query<ApiResponse<any>, ResetTwoFaPayload>({
      query: (payload) => ({
        url: `/v2/2fa/email/enable/${payload.user_id}`,
        method: 'GET',
      }),
    }),

    confirmEnableTwoFaEmail: builder.mutation<ApiResponse<any>, VerifyOtpPayload>({
      query: (payload) => ({
        url: '/v2/2fa/email/confirm-switch',
        method: 'POST',
        body: generateFormData(payload),
      }),
    }),
  }),
});

export const {
  useLazyCheckSessionQuery,
  useLazyGetCSRFCookieQuery,
  useLazyLogoutQuery,
  useLoginMutation,
  useSignupMutation,
  useConfirmEnableTwoFaEmailMutation,
  useConfirmEnableTwoFaAppMutation,
  useDisableTwoFaAppMutation,
  useVerifyOtpMutation,
  useLazyEnableTwoFaAppQuery,
  useLazyResetTwoFaAppQuery,
} = authSessionApi;
