import { useEffect } from 'react';

import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import {
  useAddWhiteListMutation,
  useDeleteWhiteListMutation,
  useLazyFetchWhiteListsQuery,
  useUpdateWhiteListMutation,
} from '../api/necs-white-list.api';
import { NecsWhiteListAC, setNecsSelectedWhiteList, setNecsWhiteListModal } from '../store/necs-white-list.slice';
import {
  INecsWhiteList,
  INecsWhiteListModal,
  NecsAddWhiteListPayload,
  NecsDeleteWhiteListPayload,
  NecsUpdateWhiteListPayload,
} from '../types';

interface OpenModal {
  modal: INecsWhiteListModal;
  whiteList?: INecsWhiteList;
}

interface UseNecsWhiteList {
  fetchWhiteListsHandler: () => Promise<boolean>;
  addWhiteListHandler: (payload: NecsAddWhiteListPayload) => Promise<boolean>;
  updateWhiteListHandler: (payload: NecsUpdateWhiteListPayload) => Promise<boolean>;
  deleteWhiteListHandler: (payload: NecsDeleteWhiteListPayload) => Promise<boolean>;
  openNecsWhiteListModal: ({ modal, whiteList }: OpenModal) => void;
  closeNecsWhiteListModal: (refetch?: boolean) => void;
}

export const useNecsWhiteList = (): UseNecsWhiteList => {
  const dispatch = useAppDispatch();

  const [fetchWhiteListsQuery] = useLazyFetchWhiteListsQuery();
  const [addWhiteListQuery] = useAddWhiteListMutation();
  const [updateWhiteListQuery] = useUpdateWhiteListMutation();
  const [deleteWhiteListQuery] = useDeleteWhiteListMutation();

  const fetchWhiteListsHandler = async () => {
    dispatch(NecsWhiteListAC.toggleLoading(true));
    const { data: response, error } = await fetchWhiteListsQuery({});
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Whitelist!');
    } else {
      dispatch(NecsWhiteListAC.clearItems());
      dispatch(NecsWhiteListAC.setItems(response.data));
    }

    dispatch(NecsWhiteListAC.toggleLoading(false));
    return !isError;
  };

  const addWhiteListHandler = async (payload: NecsAddWhiteListPayload) => {
    const { data: response, error } = await addWhiteListQuery(payload);
    const isError = error || !response?.data;

    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed add Company to whitelist!');
    } else {
      toast.success('Partner company successfully added!');
      await fetchWhiteListsHandler();
    }
    return !isError;
  };

  const updateWhiteListHandler = async (payload: NecsUpdateWhiteListPayload) => {
    dispatch(NecsWhiteListAC.toggleLoading(true));
    const { data: response, error } = await updateWhiteListQuery(payload);
    dispatch(NecsWhiteListAC.toggleLoading(false));
    const isError = error || !response?.data;

    if (isError || !response?.data) {
      toast.error(getApiErrorMessage(error) || 'Failed to update Company!');
    } else {
      toast.success('Partner company successfully updated!');
      dispatch(NecsWhiteListAC.editItem(response.data));
    }
    return !isError;
  };

  const deleteWhiteListHandler = async (payload: NecsDeleteWhiteListPayload) => {
    const { error } = await deleteWhiteListQuery(payload);
    const isError = error;

    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to delete Company!');
    } else {
      await fetchWhiteListsHandler();
    }
    return !isError;
  };

  const openNecsWhiteListModal = ({ modal, whiteList }: OpenModal) => {
    dispatch(setNecsWhiteListModal(modal));
    if (whiteList) dispatch(setNecsSelectedWhiteList(whiteList));
  };

  const closeNecsWhiteListModal = async (refetch = false) => {
    if (refetch) {
      await fetchWhiteListsHandler();
    }
    dispatch(setNecsSelectedWhiteList(null));
    dispatch(setNecsWhiteListModal(null));
  };

  useEffect(() => {
    return () => {
      dispatch(NecsWhiteListAC.clearItems());
    };
  }, []);

  return {
    addWhiteListHandler,
    deleteWhiteListHandler,
    fetchWhiteListsHandler,
    updateWhiteListHandler,
    closeNecsWhiteListModal,
    openNecsWhiteListModal,
  };
};
