import { ISearchNecsAccount } from 'modules/necs/necs-account';

export interface INecsWhiteList {
  id: number;
  is_trust_payment: number | boolean;
  organization_name: string;
}

export interface NecsAddWhiteListPayload {
  target_necs_account_id: number;
  is_trust_payment: number | boolean;
}

export interface NecsUpdateWhiteListPayload {
  id: number;
  is_trust_payment: number | boolean;
}

export interface NecsDeleteWhiteListPayload {
  id: number;
}

export enum ENecsWhiteListModals {
  ADD_PARTNER = 'add_partner',
  CONFIRM = 'confirm',
}

export enum ENecsWhiteListModalAction {
  DELETE = 'delete',
  ADD_CONFIRM = 'add_confirm',
  ADD_TRUST_CONFIRM = 'add_trust_confirm',
}

export interface NecsWhiteListModalPayload {
  action?: ENecsWhiteListModalAction;
  necsAccount?: ISearchNecsAccount;
}

export interface INecsWhiteListModal {
  type: ENecsWhiteListModals;
  payload?: NecsWhiteListModalPayload;
}
