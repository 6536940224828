import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import { FetchDashboardResponse } from '../types';

export const necsDashboardApi = createApi({
  reducerPath: 'necsDashboardApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/dashboard`),
  endpoints: (builder) => ({
    fetchNecsDashboard: builder.query<ApiResponse<FetchDashboardResponse>, any>({
      query: () => ({
        url: ``,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyFetchNecsDashboardQuery } = necsDashboardApi;
