import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse, ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  FetchNecsTransactionPayload,
  INecsTransaction,
  UpdateNecsTransactionPayload,
} from '../types/necs-transaction.type';

export const necsTransactionApi = createApi({
  reducerPath: 'necsTransactionApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/transactions`),
  endpoints: (builder) => ({
    fetchNecsTransactions: builder.query<ApiPaginationResponse<INecsTransaction[]>, FetchNecsTransactionPayload>({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),
    updateNecsTransactions: builder.mutation<ApiResponse<INecsTransaction>, UpdateNecsTransactionPayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const { useLazyFetchNecsTransactionsQuery, useUpdateNecsTransactionsMutation } = necsTransactionApi;
