import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { INecsAccount, NecsAccountConnectionResult } from '../types/necs-account.types';

export interface NecsAccountInitialState extends BaseDataReducerV2InitialState<any> {
  account: INecsAccount | null;
  goAccounts: any[];
  necsAccountConnectionResult: NecsAccountConnectionResult | null;
}

const { initialState, AC, reducer } = basedDataReducerV2<any, NecsAccountInitialState>('necs-account');

const necsInitialState: NecsAccountInitialState = {
  ...initialState,
  account: null,
  goAccounts: [],
  necsAccountConnectionResult: null,
};

export const necsAccount = createSlice({
  name: 'necsAccount',
  initialState: necsInitialState,
  reducers: {
    setNecsAccount: (state, action: PayloadAction<INecsAccount | null>) => {
      state.account = action.payload;
    },
    setGoAccounts: (state, action: PayloadAction<any>) => {
      state.goAccounts = action.payload;
    },
    setNecsAccountConnectionResult: (state, action: PayloadAction<NecsAccountConnectionResult | null>) => {
      state.necsAccountConnectionResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsAccountInitialState, action);
    });
  },
});
export const NecsAccountAC = AC;

export const { setNecsAccountConnectionResult, setGoAccounts, setNecsAccount } = necsAccount.actions;
