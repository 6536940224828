import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse, ApiResponse } from 'types';

import { apiBaseQuery } from 'modules/apiBaseQuery';

import {
  AddForwardPayload,
  CloneForwardPayload,
  DeleteForwardPayload,
  FetchForwardsPayload,
  INecsForward,
  UpdateForwardPayload,
} from '../types/necs-forward.types';

export const necsForwardApi = createApi({
  reducerPath: 'necsForwardApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/forward-certificates`),
  endpoints: (builder) => ({
    fetchForwards: builder.query<ApiPaginationResponse<INecsForward[]>, FetchForwardsPayload>({
      query: (payload) => ({
        url: ``,
        method: 'GET',
        params: payload,
      }),
    }),

    addForward: builder.mutation<ApiResponse<INecsForward>, AddForwardPayload>({
      query: (payload) => ({
        url: ``,
        method: 'POST',
        body: payload,
      }),
    }),

    cloneForward: builder.query<ApiResponse<INecsForward>, CloneForwardPayload>({
      query: (payload) => ({
        url: `/${payload.id}/clone`,
        method: 'GET',
      }),
    }),

    updateForward: builder.mutation<ApiResponse<INecsForward>, UpdateForwardPayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    deleteForward: builder.mutation<ApiResponse<any>, DeleteForwardPayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'Delete',
      }),
    }),
  }),
});

export const {
  useAddForwardMutation,
  useDeleteForwardMutation,
  useUpdateForwardMutation,
  useLazyFetchForwardsQuery,
  useLazyCloneForwardQuery,
} = necsForwardApi;
