import API from '../api/api';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';

const { AC, reducer, helpers, thunks } = baseDataReducer('marketplaceHelpQuestions');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  offset: 0,
};

const marketplaceHelpQuestions = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const clearItems = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getHelpQuestionsByCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplaceHelp.getQuestionsByCategory({ category: categoryId });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
  } catch (e) {
    dispatch(AC.toggleLoading(false));
    onError({ status: 0, message: 'Error when loading help questions', deb_info: e });
  }
};

export const removeHelpQuestion =
  ({ id, hash }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      await API.marketplaceHelp.removeQuestion(hash);
      dispatch(AC.toggleInit(false));
      dispatch(AC.removeItem(id));
      dispatch(AC.toggleLoading(false));
      return true;
    } catch (error) {
      console.log(error);
      onError({ status: 0, message: 'Error on remove help question!', deb_info: error });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export const addHelpQuestion = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      getID: ['category'],
    });
    let status = await API.marketplaceHelp.addQuestion(dataToSend);
    dispatch(AC.addItem(status));
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on add help question!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const editHelpQuestion = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      getID: ['category'],
    });
    let status = await API.marketplaceHelp.editQuestion(dataToSend);
    dispatch(AC.editItem(status));
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit help question!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default marketplaceHelpQuestions;
