import React from 'react';

/* Utils. */
import classnames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';
/* Material UI */
import LinearProgress from '@material-ui/core/LinearProgress';

/* Styles */
import css from './nibLoader.module.sass';

const NibLoader = ({ line = false, ...props }) => {
  return (
    <div className={classnames(css.wrap, !line && css.cir)}>
      {line ? <LinearProgress color="primary" /> : <CircularProgress size={70} color="primary" />}
    </div>
  );
};

export default NibLoader;
