import { configureStore } from '@reduxjs/toolkit';

import { authSession, authSessionApi } from 'modules/auth-session';
import { companyType, companyTypeApi } from 'modules/company-type';
/* Reducers import */
import { appMedia, mediaApi } from 'modules/media';
import {
  necsAccount,
  necsAccountApi,
  necsDashboard,
  necsDashboardApi,
  necsForward,
  necsForwardApi,
  necsGoCertificate,
  necsGoCertificateApi,
  necsProductionDevice,
  necsProductionDeviceApi,
  necsTransaction,
  necsTransactionApi,
  necsWhiteList,
  necsWhiteListApi,
} from 'modules/necs';

import appReducer from '../app-reducer.js';
import companiesReducer from '../companies-reducer.js';
import companyRelationsReducer from '../company-relations-reducer';
import companyTypesReducer from '../company-types-reducer.js';
import documentsListReducer from '../documents-list-reducer';
import documentsReducer from '../documents-reducer';
import errorReducer from '../error-reducer.js';
import giftCardsReducer from '../gift-cards-reducer';
import imageBankReducer from '../image-bank-reducer';
import invitationsReducer from '../invitations-reducer';
import landingReducer from '../landing-reducer';
import marketplaceCategoriesReducer from '../marketplace-categories-reducer';
import marketplaceHelpCategoriesReducer from '../marketplace-help-categories-reducer';
import marketplaceHelpQuestionsReducer from '../marketplace-help-questions-reducer';
import marketplaceIconsReducer from '../marketplace-icons-reducer';
import marketplaceInventoryReducer from '../marketplace-inventory-reducer';
import marketplaceOrdersOldReducer from '../marketplace-orders-old-reducer';
import marketplaceOrdersReducer from '../marketplace-orders-reducer';
import marketplaceVariantsReducer from '../marketplace-variants-reducer';
import ordersReducer from '../orders-reducer.js';
import productTypesReducer from '../product-types-reducer';
import productWrapperTypesReducer from '../product-wrapper-types-reducer';
import productWrappersReducer from '../product-wrappers-reducer';
import productsReducer from '../products-reducer.js';
import reviewCompaniesReducer from '../review-companies-reducer';
import reviewProductsReducer from '../review-products-reducer';
import routerReducer from '../router-reducer';
import sessionReducer from '../session-reducer.js';
import usersReducer from '../users-reducer';
import vaultReducer from '../vault-reducer';
import './store-listener';
import { listenerMiddleware } from './store-listener';

let store = configureStore({
  reducer: {
    app: appReducer,
    session: sessionReducer,
    errors: errorReducer,
    companies: companiesReducer,
    ///
    companyTypes: companyTypesReducer,
    ///
    products: productsReducer,
    productWrappers: productWrappersReducer,
    productWrapperTypes: productWrapperTypesReducer,
    orders: ordersReducer,
    users: usersReducer,
    landing: landingReducer,
    documents: documentsReducer,
    vault: vaultReducer,
    images: imageBankReducer,
    giftCards: giftCardsReducer,
    marketplaceIcons: marketplaceIconsReducer,
    marketplaceHelpCategories: marketplaceHelpCategoriesReducer,
    marketplaceHelpQuestions: marketplaceHelpQuestionsReducer,
    marketplaceCategories: marketplaceCategoriesReducer,
    marketplaceInventory: marketplaceInventoryReducer,
    marketplaceOrdersOld: marketplaceOrdersOldReducer,
    marketplaceOrders: marketplaceOrdersReducer,
    marketplaceVariants: marketplaceVariantsReducer,
    companyRelations: companyRelationsReducer,
    productTypes: productTypesReducer,
    documentsList: documentsListReducer,
    reviewProduct: reviewProductsReducer,
    reviewCompany: reviewCompaniesReducer,
    invitations: invitationsReducer,
    router: routerReducer,

    [appMedia.name]: appMedia.reducer,

    [authSession.name]: authSession.reducer,

    [companyType.name]: companyType.reducer,

    [necsAccount.name]: necsAccount.reducer,
    [necsProductionDevice.name]: necsProductionDevice.reducer,
    [necsGoCertificate.name]: necsGoCertificate.reducer,
    [necsTransaction.name]: necsTransaction.reducer,
    [necsWhiteList.name]: necsWhiteList.reducer,
    [necsDashboard.name]: necsDashboard.reducer,
    [necsForward.name]: necsForward.reducer,

    // API
    [mediaApi.reducerPath]: mediaApi.reducer,
    [authSessionApi.reducerPath]: authSessionApi.reducer,

    [companyTypeApi.reducerPath]: companyTypeApi.reducer,

    [necsAccountApi.reducerPath]: necsAccountApi.reducer,
    [necsProductionDeviceApi.reducerPath]: necsProductionDeviceApi.reducer,
    [necsGoCertificateApi.reducerPath]: necsGoCertificateApi.reducer,
    [necsTransactionApi.reducerPath]: necsTransactionApi.reducer,
    [necsWhiteListApi.reducerPath]: necsWhiteListApi.reducer,
    [necsDashboardApi.reducerPath]: necsDashboardApi.reducer,
    [necsForwardApi.reducerPath]: necsForwardApi.reducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      listenerMiddleware.middleware,

      authSessionApi.middleware,

      mediaApi.middleware,

      companyTypeApi.middleware,

      necsAccountApi.middleware,
      necsProductionDeviceApi.middleware,
      necsGoCertificateApi.middleware,
      necsTransactionApi.middleware,
      necsWhiteListApi.middleware,
      necsDashboardApi.middleware,
      necsForwardApi.middleware,
    ),
});

// @ts-ignore
window.store = store;

export default store;
