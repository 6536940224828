import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';

import { EnergyTypeResponse } from 'modules/necs/necs-production-device';

import { INecsForward, INecsForwardModal } from '../types/necs-forward.types';

export interface NecsForwardInitialState extends BaseDataReducerV2InitialState<INecsForward> {
  selectedForward: INecsForward | null;
  currentModal: INecsForwardModal | null;
  energyTypes: EnergyTypeResponse | null;
}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<INecsForward, NecsForwardInitialState>('necs-forward');

export const necsForwardInitialState: NecsForwardInitialState = {
  ...initialState,
  perPage: 25,
  selectedForward: null,
  currentModal: null,
  energyTypes: null,
};

export const necsForward = createSlice({
  name: 'necsForward',
  initialState: necsForwardInitialState,
  reducers: {
    setSelectedNecsForward: (state, action: PayloadAction<INecsForward | null>) => {
      state.selectedForward = action.payload;
    },
    setNecsForwardModal: (state, action: PayloadAction<INecsForwardModal | null>) => {
      state.currentModal = action.payload;
    },
    setEnergyTypes: (state, action: PayloadAction<EnergyTypeResponse | null>) => {
      state.energyTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsForwardInitialState, action);
    });
  },
});

export const NecsForwardAC = AC;
export const onNecsForwardSort = thunks.onSort;
export const onNecsForwardFilter = thunks.onFilter;
export const clearNecsForwardFilters = AC.clearFilters;
export const setNecsForwardCurrentPage = AC.setCurrentPage;

export const { setNecsForwardModal, setSelectedNecsForward, setEnergyTypes } = necsForward.actions;
