const SET_CHAT_USER = '/nibchain/session/SET_CHAT_USER';
const SET_UNREAD_CHANNELS = '/nibchain/session/SET_UNREAD_CHANNELS';

const initialState = {
  chatUser: null,
  unread_channels: [],
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_CHANNELS:
      return {
        ...state,
        unread_channels: action.payload,
      };
    case SET_CHAT_USER:
      return {
        ...state,
        chatUser: action.payload,
      };
    default:
      return { ...state };
  }
};

//  All Below Code For Old Chat
export const setUnreadChannels = (channels) => (dispatch) => {
  dispatch({ type: SET_UNREAD_CHANNELS, payload: channels });
};

export const addChannelToUnread = (channel) => (dispatch, getState) => {
  const {
    session: { unread_channels },
  } = getState();
  dispatch({
    type: SET_UNREAD_CHANNELS,
    payload: [...unread_channels, channel],
  });
};

export const removeChannelFromUnread = (channel) => (dispatch, getState) => {
  const {
    session: { unread_channels },
  } = getState();
  dispatch({
    type: SET_UNREAD_CHANNELS,
    payload: unread_channels.filter((c) => c !== channel),
  });
};

export const setChatUser = (user) => (dispatch) => {
  dispatch({ type: SET_CHAT_USER, payload: user });
};

export default sessionReducer;
