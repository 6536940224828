import { batch } from 'react-redux';
import { toast } from 'react-toastify';

import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';

import {
  useLazyFetchNecsGoCertificatesQuery,
  useUpdateNecsGoCertificateMutation,
} from '../api/necs-go-certificate.api';
import {
  GoCertificateAC,
  setNecsCertificateModal,
  setSelectedNecsCertificate,
} from '../store/necs-go-certificate.slice';
import {
  FetchGoCertificatePayload,
  IGoCertificate,
  INecsCertificateModal,
  UpdateGoCertificatePayload,
} from '../types/necs-go-certificate.type';

interface OpenModal {
  modal: INecsCertificateModal;
  certificate: IGoCertificate | null;
}

interface UseNecsGoCertificate {
  fetchNecsGoCertificatesHandler: (payload: FetchGoCertificatePayload) => Promise<boolean>;
  setGoCertificateCommentHandler: (payload: UpdateGoCertificatePayload) => Promise<void>;
  saveNecsExchangesHandler: (payload: UpdateGoCertificatePayload) => Promise<boolean>;
  openNecsCertificateModal: (payload: OpenModal) => void;
  closeNecsCertificateModal: () => void;
}

export const useNecsGoCertificate = (): UseNecsGoCertificate => {
  const dispatch = useAppDispatch();
  const [fetchNecsGoCertificates] = useLazyFetchNecsGoCertificatesQuery();
  const [updateNecsGoCertificate] = useUpdateNecsGoCertificateMutation();

  const fetchNecsGoCertificatesHandler = async (payload: FetchGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await fetchNecsGoCertificates(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Production devices!');
    } else {
      batch(() => {
        dispatch(GoCertificateAC.clearItems());
        dispatch(GoCertificateAC.setItems(response.data));
        dispatch(GoCertificateAC.setPaginationMeta(response.meta));
      });
    }

    dispatch(GoCertificateAC.toggleLoading(false));
    return !isError;
  };

  const setGoCertificateCommentHandler = async (payload: UpdateGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await updateNecsGoCertificate(payload);
    dispatch(GoCertificateAC.toggleLoading(false));
    const isError = error || !response?.data;
    if (isError) {
      toast.error('Failed to change comment for Certificate Bundle!');
      return;
    }
    toast.success('Comment for Certificate Bundle successfully saved!');
  };

  const saveNecsExchangesHandler = async (payload: UpdateGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await updateNecsGoCertificate(payload);

    const isError = error || !response?.data;
    if (isError) {
      toast.error('Failed to save exchanges of NECS GO certificate!');
      dispatch(GoCertificateAC.toggleLoading(false));
      return false;
    }
    await fetchNecsGoCertificatesHandler({ page: 1, per_page: 25 });
    toast.success('Exchanges of NECS GO certificate successfully saved!');
    return true;
  };

  const openNecsCertificateModal = ({ modal, certificate }: OpenModal) => {
    batch(() => {
      dispatch(setSelectedNecsCertificate(certificate));
      dispatch(setNecsCertificateModal(modal));
    });
  };

  const closeNecsCertificateModal = () => {
    batch(() => {
      dispatch(setSelectedNecsCertificate(null));
      dispatch(setNecsCertificateModal(null));
    });
  };

  return {
    fetchNecsGoCertificatesHandler,
    setGoCertificateCommentHandler,
    saveNecsExchangesHandler,
    closeNecsCertificateModal,
    openNecsCertificateModal,
  };
};
