import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { useAppSelector } from 'redux/store/store.types';
import * as Yup from 'yup';

import { useAuthSession } from 'modules/auth-session';

import { Checkbox, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

import NibCountrySelector from '@components/common/NibCountrySelector/NibCountrySelector';
import NibPasswordInput from '@components/common/NibPasswordInput';

import NibButton from 'components/common/NibButton/NibButton';
import NibLink from 'components/common/NibLink/NibLink';

import css from './registrationPage.module.sass';

const ALLOWED_EXT = /(\.png|\.jpg|\.jpeg)$/i;
const MAX_SIZE = 1000 * (1000 * 8);

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  rep_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  country_code: Yup.string().required('Country is required'),
  termsAccepted: Yup.boolean().oneOf([true], 'You must accept the terms').required('Required'),
  documentType: Yup.string(),
  idFile: Yup.mixed().when('documentType', {
    is: (documentType) => documentType !== 'none',
    then: Yup.mixed()
      .required('ID file is required')
      .test('fileSize', 'File too large', (value) => value && value.size <= MAX_SIZE)
      .test('fileFormat', 'Unsupported format', (value) => value && ALLOWED_EXT.test(value.name)),
  }),
});

const RegistrationPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { signupHandler } = useAuthSession();
  const { isLoading } = useAppSelector((state) => state.authSession);
  const { platformSettings } = useSelector((state) => state.app);

  const params = new URLSearchParams(search);
  const initialInvitationCode = params.get('invite') || '';

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      username: '',
      password: '',
      rep_password: '',
      country_code: null,
      region_code: null,
      documentType: 'none',
      idFile: null,
      invitationCode: initialInvitationCode,
      termsAccepted: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await signupHandler({
          login: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          email: values.email,
          password: values.password,
          document: values.idFile,
          country_code: values.country_code,
          region_code: values.region_code,
          invitationCode: values.invitationCode,
        });

        if (result) {
          toast.dark('Registration was successful, please Sign In !');
          history.push('/login');
        }
      } catch (error) {
        if (error?.data?.validation) {
          Object.keys(error.data.validation).forEach((key) => {
            toast.error(error.data.validation[key]);
          });
        }
      }
    },
  });

  const useInputStyles = makeStyles({
    input: {
      height: '36px',
    },
    select: {
      height: '28px',
      marginTop: '8px',
    },
    root: {
      '& .MuiFormLabel-filled': {
        marginTop: '0px !important',
      },
      '&.MuiFormLabel-filled': {
        marginTop: '0px !important',
      },
      '& .Mui-focused': {
        marginTop: '0px !important',
      },
      '& .MuiFormLabel-root': {
        color: '#898989',
      },
      '&.MuiInputLabel-root': {
        color: '#898989',
      },
      '&.MuiInputLabel-outlined': {
        color: '#898989',
      },
    },
  });

  const InputStyles = useInputStyles();

  return (
    <div className={css.content}>
      <div className={css.main}>
        <div className={css.block}>
          <div className={css.logo} />
          <div className={css.registrationInfo}>
            <Typography className={css.register}>Create your {platformSettings.companyName} account</Typography>
          </div>
          <form className="flex flex-col justify-center w-full" onSubmit={formik.handleSubmit}>
            <div className={css.registrationForm}>
              <TextField
                variant="outlined"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                label="First Name"
                classes={InputStyles}
                required
              />

              <TextField
                variant="outlined"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                label="Last Name"
                classes={InputStyles}
                required
              />

              <TextField
                variant="outlined"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                label="Username"
                classes={InputStyles}
                required
              />

              <TextField
                variant="outlined"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                label="Email"
                type="email"
                classes={InputStyles}
                required
              />

              <NibPasswordInput
                value={formik.values.password}
                onChange={(value) => formik.setFieldValue('password', value)}
                onBlur={() => formik.setFieldTouched('password', true)}
                error={formik.touched.password && Boolean(formik.errors.password)}
                label="Set a password"
                classes={InputStyles}
                required
              />

              <NibPasswordInput
                value={formik.values.rep_password}
                onChange={(value) => formik.setFieldValue('rep_password', value)}
                onBlur={() => formik.setFieldTouched('rep_password', true)}
                error={formik.touched.rep_password && Boolean(formik.errors.rep_password)}
                label="Repeat password"
                classes={InputStyles}
                required
              />

              <NibCountrySelector
                onChange={(data) => {
                  formik.setFieldValue('country_code', data.country?.code || null);
                  formik.setFieldValue('region_code', data.region?.code || null);
                }}
                countryError={formik.touched.country_code && formik.errors.country_code}
                countryReq={true}
                classes={InputStyles}
              />

              <FormControl variant="outlined">
                <InputLabel classes={InputStyles}>Select ID type</InputLabel>
                <Select
                  name="documentType"
                  value={formik.values.documentType}
                  onChange={formik.handleChange}
                  classes={InputStyles}
                  label="Select ID type"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="drivers_license">Drivers license</MenuItem>
                </Select>
                {formik.values.documentType !== 'none' && (
                  <div className={css.uploadWrap}>
                    {formik.values.idFile && <span>Size: {formik.values.idFile.size / 1000 / 1000}mb</span>}
                    <Button variant="contained" color="primary" component="label">
                      Upload
                      <input
                        type="file"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue('idFile', file);
                        }}
                      />
                    </Button>
                    {formik.touched.idFile && formik.errors.idFile && (
                      <div className={css.error}>{formik.errors.idFile}</div>
                    )}
                  </div>
                )}
              </FormControl>
            </div>

            <div className="flex flex-col">
              <div className={css.terms}>
                <Checkbox
                  checked={formik.values.termsAccepted}
                  onChange={(e) => formik.setFieldValue('termsAccepted', e.target.checked)}
                />
                <div className={css.links}>
                  <NibLink target="blank" href={`${process.env.REACT_APP_MARKETPLACE_FRONT_URL}/terms-conditions`}>
                    I have read and accept Terms and Conditions
                  </NibLink>
                </div>
              </div>
              {formik.touched.termsAccepted && formik.errors.termsAccepted && (
                <div className={css.error}>{formik.errors.termsAccepted}</div>
              )}
            </div>

            <div className="w-full flex justify-center">
              <NibButton
                type="submit"
                className={css.registerBtn}
                mui
                variant="outlined"
                size="large"
                label="Register"
                disabled={isLoading}
              />
            </div>
          </form>

          <div className={css.links}>
            <p>
              Already have an account? <NibLink to="/login">Sign In</NibLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
