import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '@api';

import { Button, TextField, Typography } from '@material-ui/core';

/* Components */
import NibButton from '../../components/common/NibButton/NibButton';
import NibPasswordInput from '../../components/common/NibPasswordInput';
/* Styles */
import css from './passwordResetPage.module.sass';

const PasswordResetPage = ({}) => {
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [response, setResponce] = useState(null);
  const [token, setToken] = useState(null);
  const [newPassword, setNewPassword] = useState('');

  const onReset = async () => {
    if (email) {
      let status = await api.user.resetPassword(email);
      if (status) {
        toast.success(status.info);
        setResponce(status.info);
      }
    }
  };
  const onChangePassword = async () => {
    if (newPassword) {
      let data = {
        token: token,
        new_password: newPassword,
      };
      let status = await api.user.resetPasswordConfirmation(data);
      if (status) {
        toast.success(status.info);
        history.push('/login');
      }
    }
  };

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    if (params.has('token')) {
      setToken(params.get('token'));
    }
  }, [location]);

  return (
    <div className={css.content}>
      <div className={css.main}>
        <div className={css.block}>
          <div className={css.logo}></div>
          <Typography className={css.forgot}>{token ? 'Set new password' : 'Forgot password?'}</Typography>
          {!token && (
            <Typography className={css.info}>
              Enter your email address below, and we’ll send you an email allowing you to reset it.
            </Typography>
          )}
          <div className={css.form}>
            {response ? (
              <div className={css.response}>{response}</div>
            ) : (
              <>
                {token ? (
                  <NibPasswordInput
                    value={newPassword}
                    onChange={(value) => setNewPassword(value)}
                    label=""
                    placeholder="Set a password"
                    className={css.setPassword}
                  />
                ) : (
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    variant={'outlined'}
                    fullWidth={true}
                  />
                )}

                {token ? (
                  <NibButton
                    className={css.resetBtn}
                    mui
                    variant="outlined"
                    size="large"
                    label="Change password"
                    onClick={onChangePassword}
                    fullWidth={true}
                  />
                ) : (
                  <NibButton
                    className={css.resetBtn}
                    mui
                    variant="outlined"
                    size="large"
                    label="Reset password"
                    onClick={onReset}
                    fullWidth={true}
                  />
                )}
                <Button
                  className={css.cancelBtn}
                  variant="text"
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetPage;
