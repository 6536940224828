import {
  NecsPDHydroIcon,
  NecsPDMarineIcon,
  NecsPDNuclearIcon,
  NecsPDOtherIcon,
  NecsPDSolarIcon,
  NecsPDThermalIcon,
  NecsPDWindIcon,
  NecsSourceFossilIcon,
  NecsSourceNuclearIcon,
  NecsSourceOtherIcon,
  NecsSourceRenewableIcon,
  NecsSourceSynGasIcon,
  NecsSourceWastTempIcon,
} from 'components/Icons';

export interface IExchangeListingItem {
  id: number;
  price: number;
  volume: number;
}

export interface IExchangeModalData {
  listingData: IExchangeListingItem[];
  totalVolume: number;
  onExchangeVolume: number;
  reservedVolume: number;
}

export enum EProductionTechnology {
  SOLAR = 'T01',
  WIND = 'T02',
  HYDRO = 'T03',
  MARINE = 'T04',
  THERMAL = 'T05',
  NUCLEAR = 'T06',
  OTHER = 'T07',
}

export enum EEnergySource {
  UNSPECIFIED = 'F00',
  RENEWABLE = 'F01',
  FOSSIL = 'F02',
  NUCLEAR = 'F03',
  GAS_SYNTHESIS = 'F04',
  WASTE_HEAT_COLD = 'F05',
}

export const EnergySourceTypeMap: Record<EEnergySource, { name: string; icon?: string }> = {
  [EEnergySource.UNSPECIFIED]: {
    name: 'Unspecified',
    icon: NecsSourceOtherIcon,
  },
  [EEnergySource.FOSSIL]: {
    name: 'Fossil',
    icon: NecsSourceFossilIcon,
  },
  [EEnergySource.GAS_SYNTHESIS]: {
    name: 'Gas Synthesis',
    icon: NecsSourceSynGasIcon,
  },
  [EEnergySource.NUCLEAR]: {
    name: 'Nuclear',
    icon: NecsSourceNuclearIcon,
  },
  [EEnergySource.RENEWABLE]: {
    name: 'Renewable',
    icon: NecsSourceRenewableIcon,
  },
  [EEnergySource.WASTE_HEAT_COLD]: {
    name: 'Waste Heat Cold',
    icon: NecsSourceWastTempIcon,
  },
};

export const TechnologyTypeMap: Record<EProductionTechnology, { name: string; icon?: string }> = {
  [EProductionTechnology.WIND]: {
    name: 'Wind',
    icon: NecsPDWindIcon,
  },
  [EProductionTechnology.HYDRO]: {
    name: 'Hydro',
    icon: NecsPDHydroIcon,
  },
  [EProductionTechnology.SOLAR]: {
    name: 'Solar',
    icon: NecsPDSolarIcon,
  },
  [EProductionTechnology.THERMAL]: {
    name: 'Thermal',
    icon: NecsPDThermalIcon,
  },
  [EProductionTechnology.NUCLEAR]: {
    name: 'Nuclear',
    icon: NecsPDNuclearIcon,
  },
  [EProductionTechnology.MARINE]: {
    name: 'Marine',
    icon: NecsPDMarineIcon,
  },
  [EProductionTechnology.OTHER]: {
    name: 'Other',
    icon: NecsPDOtherIcon,
  },
};
