import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const removeNullParams = (params?: Record<string, any>): Record<string, any> | undefined => {
  if (!params) return params;
  return Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== null));
};

export const apiBaseQuery = (baseUrl: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
  return async (args, api, extraOptions) => {
    if (typeof args === 'object' && 'params' in args) {
      args = { ...args, params: removeNullParams(args.params) };
    }

    const baseQuery = fetchBaseQuery({
      baseUrl,
      credentials: 'include',
      paramsSerializer: (params) => {
        const searchParams = new URLSearchParams();

        for (const key in params) {
          const value = params[key];
          if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
              // Serialize arrays with the '[]' notation
              value.forEach((item) => {
                searchParams.append(`${key}[]`, item);
              });
            } else {
              // Serialize other values
              searchParams.append(key, value);
            }
          }
        }

        return searchParams.toString();
      },
      prepareHeaders: (headers) => {
        const xsrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('XSRF-TOKEN='))
          ?.split('=')[1];

        if (xsrfToken) {
          headers.set('X-XSRF-TOKEN', decodeURIComponent(xsrfToken));
        }
        headers.set('Accept', 'application/json');

        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions);

    return result;
  };
};
