import _ from 'lodash';
import { Dispatch } from 'redux';
import { ApiPaginationMeta } from 'types';

interface BaseItem {
  id: number | string;
}

type GenericAction<T> = {
  type: string;
  payload?: T;
};

type GenericState<ItemType> = {
  isLoading: boolean;
  items: ItemType[];
  perPage: number;
  currentPage: number;
  paginationMeta: ApiPaginationMeta | null;
  filters: Record<string, any>;
  sorting: Record<string, any>;
};

const createActions = (section: string) => ({
  TOGGLE_LOADING: `/app/${section}/TOGGLE_LOADING`,

  SET_CURRENT_PAGE: `/app/${section}/SET_CURRENT_PAGE`,

  SET_ITEMS: `/app/${section}/SET_ITEMS`,
  CLEAR_ITEMS: `/app/${section}/CLEAR_ITEMS`,
  EDIT_ITEM: `/app/${section}/EDIT_ITEM`,

  SET_PAGINATION_META: `/app/${section}/SET_PAGINATION_META`,
  SET_SORT: `/app/${section}/SET_SORT`,

  ADD_FILTER: `/app/${section}/ADD_FILTER`,
  REMOVE_FILTER: `/app/${section}/REMOVE_FILTER`,
  CLEAR_FILTERS: `/app/${section}/CLEAR_FILTERS`,

  CLEAR_SORTS: `/app/${section}/CLEAR_SORTS`,
});

const baseReducer =
  <ItemType extends BaseItem, StateType extends GenericState<ItemType>>(ACTIONS: ReturnType<typeof createActions>) =>
  (state: StateType, action: GenericAction<any>): StateType => {
    switch (action.type) {
      case ACTIONS.TOGGLE_LOADING:
        return { ...state, isLoading: action.payload };

      case ACTIONS.SET_ITEMS:
        return {
          ...state,
          items: action.payload.length ? [...state.items, ...action.payload] : state.items,
        };

      case ACTIONS.EDIT_ITEM:
        return {
          ...state,
          items: state.items.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
        };

      case ACTIONS.SET_PAGINATION_META:
        return { ...state, paginationMeta: action.payload };

      case ACTIONS.CLEAR_ITEMS:
        return { ...state, items: [] };

      case ACTIONS.ADD_FILTER:
        return {
          ...state,
          filters: { ...state.filters, [action.payload.filter]: action.payload.value },
          paginationMeta: null,
          items: [],
        };

      case ACTIONS.SET_SORT:
        return {
          ...state,
          sorting: { [action.payload.sort]: action.payload.value },
          paginationMeta: null,
          items: [],
        };

      case ACTIONS.REMOVE_FILTER:
        return {
          ...state,
          filters: _.omit(state.filters, [action.payload]),
          paginationMeta: null,
          items: [],
        };

      case ACTIONS.CLEAR_FILTERS:
        return {
          ...state,
          filters: {},
          paginationMeta: null,
          items: [],
        };

      case ACTIONS.CLEAR_SORTS:
        return {
          ...state,
          sorting: {},
          paginationMeta: null,
          items: [],
        };

      case ACTIONS.SET_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };

      default:
        return state;
    }
  };

const generateActionCreators = <ItemType>(ACTIONS: ReturnType<typeof createActions>) => ({
  toggleLoading: (status: boolean) => ({ type: ACTIONS.TOGGLE_LOADING, payload: status }),
  setItems: (items: ItemType[]) => ({ type: ACTIONS.SET_ITEMS, payload: items }),
  editItem: (item: ItemType) => ({ type: ACTIONS.EDIT_ITEM, payload: item }),
  clearItems: () => ({ type: ACTIONS.CLEAR_ITEMS }),
  addFilter: (filter: string, value: any) => ({
    type: ACTIONS.ADD_FILTER,
    payload: { filter, value },
  }),
  setSort: (sort: string, value: any) => ({ type: ACTIONS.SET_SORT, payload: { sort, value } }),
  removeFilter: (filter: string) => ({ type: ACTIONS.REMOVE_FILTER, payload: filter }),
  clearFilters: () => ({ type: ACTIONS.CLEAR_FILTERS }),
  setCurrentPage: (offset: number) => ({ type: ACTIONS.SET_CURRENT_PAGE, payload: offset }),
  clearSorts: () => ({ type: ACTIONS.CLEAR_SORTS }),
  setPaginationMeta: (meta: ApiPaginationMeta | null) => ({ type: ACTIONS.SET_PAGINATION_META, payload: meta }),
});

const onSortThunk =
  <ItemType>(AC: ReturnType<typeof generateActionCreators<ItemType>>) =>
  (sort: string, value: any) =>
  (dispatch: Dispatch) => {
    if (value !== null && value !== undefined) {
      dispatch(AC.setSort(sort, value));
    }
  };

const onFilterThunk =
  <ItemType>(AC: ReturnType<typeof generateActionCreators<ItemType>>) =>
  (filter: string, value: any) =>
  (dispatch: Dispatch) => {
    if (filter === 'reset') {
      dispatch(AC.clearFilters());
    } else if (value !== null && value !== undefined) {
      dispatch(AC.addFilter(filter, value));
      dispatch(AC.setCurrentPage(1));
    } else {
      dispatch(AC.removeFilter(filter));
    }
  };

export const basedDataReducerV2 = <ItemType extends BaseItem, ExtendedState extends GenericState<ItemType>>(
  section: string,
) => {
  const ACTIONS = createActions(section);
  const actionCreators = generateActionCreators<ItemType>(ACTIONS);

  const initialState: ExtendedState = {
    isLoading: false,
    items: [] as ItemType[],
    filters: {},
    sorting: {},
    perPage: 25,
    currentPage: 1,
    paginationMeta: null,
  } as ExtendedState;

  return {
    reducer: baseReducer<ItemType, ExtendedState>(ACTIONS),
    ACTIONS,
    AC: actionCreators,
    initialState,
    thunks: {
      onSort: onSortThunk(actionCreators),
      onFilter: onFilterThunk(actionCreators),
    },
  };
};

export type BaseDataReducerV2InitialState<ItemType extends BaseItem> = GenericState<ItemType>;
